import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { PollRelatedEndPoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";

const AddPoll = (props) => {
  const { user, handleGetPolls, locationData } = props;
  const [optionInput, setOptionInput] = useState(false);
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [loader, setLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [title, settitle] = useState("");
  const [duration, setduration] = useState(0);

  const addData = () => {
    if (newData.trim() !== "") {
      setData([...data, newData]);
      setNewData("");
    }
  };
  useEffect(() => {
    seterrorMessage("");
    if (data?.includes(newData)) {
      seterrorMessage("This is already an option");
    }
  }, [newData]);

  const endApiCall = () => {
    settitle("");
    setData([]);
    setduration("");
    seterrorMessage("");
    setLoader(false);
    const closeModalDom = document.getElementById("add_new_poll");
    if (closeModalDom) {
      closeModalDom.click();
    }
  };
  const handleEditData = () => {
    if (editIndex === null || newData.trim() === "") return;
    const updatedData = [...data];
    updatedData[editIndex] = newData;
    setData(updatedData);
    setEditIndex(null);
    setNewData("");
  };
  const handleRemoveData = (index) => {
    const updatedData = data.filter((_, i) => i !== index);
    setData(updatedData);
  };

  const openEditInput = (index) => {
    setEditIndex(index);
    setNewData(data[index]);
  };

  const handleAddPoll = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    const payload = {
      title,
      options: data,
      businessId: getCurrentWorkspaceId(),
      createdBy: user?._id,
      expiresAt: parseInt(duration),
      moduleType:
        locationData
          ? locationData?.moduleType
          : "Business",
      moduleId:
        locationData ? locationData?.moduleId : "",
    };
    const res = await ApiCall("post", PollRelatedEndPoints.addPoll, payload);
    if (res?.success) {
      toast.success("Poll has been created successfully!");
      handleGetPolls();
      endApiCall();
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  return (
    <div
      id="add_new_poll"
      className="modal fade"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{
        pointerEvents: loader ? "none" : "",
      }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={endApiCall}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="mb-1 fw-300">Start Poll</h5>

            <form onSubmit={handleAddPoll}>
              <div className="border-top p-3 d-flex flex-column gap-3">
                <div className="d-flex justify-content-center mt-1">
                  <div
                    className="alert alert-info text-primary fs-7"
                    role="alert"
                  >
                    <strong>Note:</strong> Poll will be scheduled for{" "}
                    <span className="fw-bold">
                      {locationData
                        ? locationData?.moduleName
                        : "Company"}
                    </span>{" "}
                    members only.
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between gap-4">
                  <p className="fs-5 text-dark">Question</p>
                  <select
                    required
                    value={duration}
                    onChange={(e) => setduration(e.target.value)}
                    class="form-select form-select-sm w-30"
                    aria-label="Default select example"
                  >
                    <option value="" selected>
                      Select Duration
                    </option>
                    <option value={24}>One day</option>
                    <option value={48}>Two day</option>
                    <option value={72}>One week</option>
                  </select>
                </div>
                <input
                  required
                  className="border-0 border-bottom py-1"
                  style={{ outline: 0 }}
                  type="text"
                  value={title}
                  onChange={(e) => settitle(e.target.value)}
                  placeholder="Ask Question"
                />
                <div className="d-flex align-items-center justify-content-between">
                  <p className="text-dark fs-5">
                    Options (Add atleast 2 Options)
                  </p>
                  <span
                    className="btn btn-sm btn-primary"
                    onClick={() => setOptionInput(true)}
                  >
                    Add Options
                  </span>
                </div>
                {optionInput && (
                  <div className="d-flex align-items-center gap-2">
                    <input
                      required
                      className="border-0 py-1 border-bottom w-100"
                      style={{ outline: 0 }}
                      type="text"
                      placeholder="Add any option as per your requirements"
                      onChange={(e) => setNewData(e.target.value)}
                    />
                    <span
                      className="btn btn-icon btn-outline-light btn-sm px-2"
                      onClick={() => setOptionInput(false)}
                    >
                      <span className="feather-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-x-lg"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                        </svg>
                      </span>
                    </span>
                    <button
                      disabled={errorMessage !== ""}
                      className="btn btn-soft-primary btn-sm"
                      onClick={() => {
                        setOptionInput(false);
                        addData();
                      }}
                    >
                      Add
                    </button>
                  </div>
                )}
                <div
                  className="d-flex flex-column gap-1 simplebar-hide-scrollbar"
                  style={{ overflow: "scroll", maxHeight: "140px" }}
                >
                  {data.map((item, index) => (
                    <>
                      {editIndex === index ? (
                        <div
                          className="d-flex align-items-center gap-1"
                          key={index}
                        >
                          <input
                            className="border px-2 rounded-2 py-1 w-100 me-1"
                            style={{ outline: 0 }}
                            type="text"
                            value={newData}
                            onChange={(e) => setNewData(e.target.value)}
                          />
                          <span
                            className="btn btn-outline-light btn-sm"
                            onClick={() => {
                              setEditIndex(null);
                              setNewData("");
                            }}
                          >
                            Discard
                          </span>
                          <span
                            className="btn btn-outline-primary btn-sm"
                            onClick={handleEditData}
                          >
                            Update
                          </span>
                        </div>
                      ) : (
                        <div
                          className="d-flex align-items-center justify-content-between gap-1"
                          key={index}
                        >
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              checked
                            />
                            <label className="form-check-label" htmlFor={index}>
                              {item}
                            </label>
                          </div>
                          <div className="d-flex align-items-center gap-1">
                            <span
                              className="btn btn-icon btn-rounded btn-sm btn-outline-light"
                              onClick={() => openEditInput(index)}
                            >
                              <span className="feather-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-pencil"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                </svg>
                              </span>
                            </span>
                            <span
                              className="btn btn-icon btn-rounded btn-sm btn-outline-danger"
                              onClick={() => handleRemoveData(index)}
                            >
                              <span className="feather-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-trash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                </svg>
                              </span>
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center">
                <button
                  type="button"
                  disabled={loader}
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={endApiCall}
                >
                  Cancel
                </button>
                <button
                  disabled={loader || data?.length < 2}
                  type="submit"
                  className="btn btn-primary"
                >
                  {loader ? <ApiLoader /> : "Start Poll"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPoll;
