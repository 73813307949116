import React from "react";
import { Avatar } from "../../../../components/Avatar/Avatar";
import { useNavigate } from "react-router-dom";
import { navigateToNoticeDetails } from "../../../../Navigation/Navigation";
import { dateAndTimeFormatter } from "../../../../global-functions/globalFunctions";

const NoticeCard = ({ allNotice, locationData, page, pageLimit }) => {
  const navigate = useNavigate();

  return (
    <div className="p-2">
      <div className="col-md-12">
        <div className="row">
          {allNotice?.map((notice, index) => {
            return (
              <div className="col-md-6">
                <div
                  className="card card-border shadow pointer"
                  onClick={() => {
                    if (locationData) {
                      navigate(navigateToNoticeDetails({ id: notice?._id }), {
                        state: {
                          moduleType: locationData?.moduleType,
                          moduleId: locationData?.moduleId,
                          moduleName: locationData?.moduleName,
                        },
                      });
                    } else {
                      navigate(navigateToNoticeDetails({ id: notice?._id }));
                    }
                  }}
                >
                  <span className="ms-2 mt-1">
                    {(page - 1) * pageLimit + (index + 1)} .
                  </span>
                  <div className="rounded-5 d-flex flex-column gap-3 p-3 pt-2">
                    <div className="d-flex justify-content-between gap-4">
                      <div className="d-flex align-items-center gap-2">
                        <span className="position-relative">
                          <Avatar
                            name={notice?.raisedBy?.name}
                            count={1}
                            image={notice?.raisedBy?.image}
                            size="xs"
                            color="primary"
                          />
                          <span
                            className="badge badge-primary badge-indicator position-bottom-end-overflow-1 element-to-animate"
                            style={{ backgroundColor: "#16db8c" }}
                          ></span>
                        </span>
                        <div>
                          <p className="text-dark">{notice?.raisedBy?.name}</p>
                        </div>
                      </div>
                      <div>
                        <span
                          className={`badge badge-md badge-soft-${
                            notice?.isDeleted === true
                              ? "red"
                              : notice?.publishStatus === "unpublished"
                              ? "yellow"
                              : "green"
                          }`}
                        >
                          {notice?.isDeleted === true
                            ? "Deleted"
                            : notice?.publishStatus}
                        </span>
                      </div>
                      <div className="d-flex align-items-start gap-3">
                        <span className="icon">
                          <span className="position-relative">
                            <span className="feather-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-eye-slash-fill me-1"
                                viewBox="0 0 16 16"
                              >
                                <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                              </svg>
                            </span>
                            <span
                              className="badge badge-indicator text-secondary mt-1"
                              style={{
                                backgroundColor: "transparent",
                                verticalAlign: "text-top",
                              }}
                            >
                              {notice?.unseenBy}
                            </span>
                          </span>
                        </span>
                        <span className="icon">
                          <span className="position-relative">
                            <span className="feather-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-eye-fill me-1"
                                viewBox="0 0 16 16"
                              >
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                              </svg>
                            </span>
                            <span
                              className="badge badge-indicator text-secondary mt-1"
                              style={{
                                backgroundColor: "transparent",
                                verticalAlign: "text-top",
                              }}
                            >
                              {notice?.seenBy}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <div className="d-flex flex-column align-items-center gap-2 w-25">
                        <img
                          className="w-75 rounded h-100p"
                          src={
                            notice?.images?.length
                              ? notice?.images?.[0]
                              : "https://www.ncenet.com/wp-content/uploads/2020/04/No-image-found.jpg"
                          }
                          alt=""
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                      <div
                        className=" rounded-4 py-2 px-3 d-flex flex-column gap-2 h-125p simplebar-hide-scrollbar w-75"
                        style={{
                          backgroundColor: "#F5F5F5",
                          overflow: "auto",
                        }}
                      >
                        <p
                          className="fw-semibold text-dark text-truncate"
                          style={{ textTransform: "capitalize" }}
                        >
                          {notice?.title}
                        </p>
                        <p
                          className="text-dark fs-7 text-truncate"
                          style={{ textTransform: "capitalize" }}
                        >
                          {notice?.description}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="fs-7">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-geo-alt ms-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                          <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>
                        <span className="text-dark ms-1 text-capitalize">
                          {notice?.noticeType === "Business"
                            ? "Company"
                            : notice?.noticeType}{" "}
                          {notice?.moduleId && `(${notice?.moduleId?.name})`}
                        </span>
                      </p>
                      <p className="fs-7">
                        Published on : {dateAndTimeFormatter(notice?.createdAt)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NoticeCard;
