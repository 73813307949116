import React, { useEffect, useState } from "react";
import {
  filterSearchData,
  getCurrentWorkspaceId,
  reversesDateFormatter,
  startApiCall,
} from "../../global-functions/globalFunctions";
import ErrorMessage from "../../components/Error/ErrorMessage";
import ApiLoader from "../../components/Loaders/ApiLoader/ApiLoader";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";
import { Avatar } from "../../components/Avatar/Avatar";
import SelectEmployeeModal from "../../components/SelectEmployeeModal/SelectEmployeeModal";
import { useSelector } from "react-redux";
import ApiCall from "../../api/ApiCall";
import { listEndpoint, taskEndpoint } from "../../api/Endpoints";
import { toast } from "react-toastify";

const AddTaskInMyTasks = ({ handleGetTask }) => {
  const user = useSelector((state) => state.user);
  var date = reversesDateFormatter(new Date());

  // ****** FOR LISTS ******
  const [allList, setAllList] = useState([]);
  const [loader2, setloader2] = useState(false);
  const [selectList, setSelectList] = useState("");
  const [listId, setListId] = useState("");
  const [filter, setFilter] = useState("");

  const filteredLists = filterSearchData(allList, filter);

  // ****** FOR TASKS ******
  const [taskName, settaskName] = useState("");
  const [taskdescription, settaskdescription] = useState("");
  const [dueDate, setdueDate] = useState(date);
  const [priority, setPriority] = useState("NO_PRIORITY");
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSelectedEmployeeModal, setisSelectedEmployeeModal] = useState(false);
  const [selectedEmployees, setselectedEmployees] = useState([]);
  const [selectedEmpId, setselectedEmpId] = useState([]);

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(500);

  const handleReset = () => {
    setloader(false);
    setErrorMessage("");
    setselectedEmployees([]);
    setselectedEmpId([]);
    settaskName("");
    settaskdescription("");
    setdueDate(date);
    setPriority("NO_PRIORITY");
    setListId("");
    setSelectList("");
    setFilter("");
    const closeModalDom = document.getElementById("add_task_mytask");
    if (closeModalDom) closeModalDom.click();
  };

  const handlePriorityChange = (event) => {
    const selectedPriority = event.target.value;
    setPriority(selectedPriority);
  };

  const handleGetAllList = async () => {
    startApiCall(null, setloader2);
    const data = {
      pageNumber: page,
      pageLimit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        employeeId: user?._id,
      },
    };
    const res = await ApiCall("post", listEndpoint.getAllList, data);
    if (res?.success) {
      setAllList(res?.list?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  // useEffect(() => {
  //   handleGetAllList();
  // }, []);

  const handleAssigntask = async (taskId) => {
    startApiCall(null, setloader);
    let temp = [];
    selectedEmpId?.map((employeeId) => {
      temp?.push({ employeeId, isRemoved: false });
    });
    const sendData = {
      taskId: taskId,
      employees: temp,
      userId: user?._id,
    };
    const res = await ApiCall("post", taskEndpoint.assignTask, sendData);
    if (res?.success) {
      toast.success("Create Task successfully!");
      handleGetTask();
      handleReset();
    } else {
      setloader(false);
    }
  };

  const handleCreatetask = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      name: taskName,
      taskType: "maintask",
      taskAbilities: "business",
      description: taskdescription,
      createdBy: user?._id,
      assignedBy: [user?._id],
      dueDate: dueDate,
      priority: priority ? priority : "NO_PRIORITY",
      businessId: getCurrentWorkspaceId(),
      list: listId,
    };
    const res = await ApiCall("post", taskEndpoint.createTask, sendData);
    if (res?.success) {
      if (selectedEmployees?.length > 0) {
        handleAssigntask(res?.task?._id);
      } else {
        toast.success("Create Task successfully!");
        handleGetTask();
        handleReset();
      }
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <>
      <div
        id="add_task_mytask"
        className="modal fade add-new-contact"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        style={{ opacity: isSelectedEmployeeModal ? 0.8 : "" }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ maxWidth: "600px" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={handleReset}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h5 className="mb-3">Add New Task</h5>
              <form onSubmit={handleCreatetask}>
                <div className="mh-575p overflow-auto">
                  <div className="title title-xs title-wth-divider text-primary text-uppercase mt-1 mb-2">
                    <span>Basic Info</span>
                  </div>
                  <div className="row gx-3 w-100">
                    <div className="col-sm-8">
                      <label className="text-dark fw-250 fs-6">
                        Select list
                      </label>
                      <div className="selectable-dropdown">
                        <div className="dropdown selectable-dropdown">
                          <span
                            className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-300p mt-2 `}
                            aria-expanded="false"
                            type="button"
                            data-bs-toggle="dropdown"
                            onClick={() => {
                              handleGetAllList();
                              setFilter("");
                            }}
                          >
                            {selectList ? (
                              <div className="d-flex justify-content-between">
                                {selectList}
                                {selectList !== "" && (
                                  <button
                                    type="button"
                                    className="btn-close btn-sm ms-2"
                                    onClick={() => {
                                      setSelectList("");
                                      setFilter("");
                                    }}
                                    aria-label="Close"
                                  ></button>
                                )}
                              </div>
                            ) : (
                              "Select Sub Role"
                            )}
                          </span>
                          <div role="menu" className="dropdown-menu">
                            <form className="d-sm-block d-none" role="search">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search Sub Role name.."
                                value={filter}
                                disabled={loader2}
                                onChange={(e) => {
                                  setPage(1);
                                  setFilter(e.target.value);
                                }}
                              />
                            </form>
                            {loader2 ? (
                              <span className="d-flex text-center mt-1">
                                Loading...
                              </span>
                            ) : (
                              <div
                                style={{
                                  maxHeight: "40vh",
                                  overflow: "auto",
                                  maxWidth: "35vw",
                                }}
                              >
                                {filteredLists?.map((data, index) => (
                                  <div
                                    className="dropdown-item text-capitalize text-truncate"
                                    key={data?._id}
                                    onClick={() => {
                                      setSelectList(data?.name);
                                      setListId(data?._id);
                                    }}
                                  >
                                    {index + 1}.
                                    <span className="fs-7">
                                      <span className="initial-wrap me-1 ms-1">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          width="15"
                                          height="15"
                                          className="mb-1"
                                        >
                                          <path d="M12.6727 1.61162 20.7999 9H17.8267L12 3.70302 6 9.15757V19.0001H11V21.0001H5C4.44772 21.0001 4 20.5524 4 20.0001V11.0001L1 11.0001 11.3273 1.61162C11.7087 1.26488 12.2913 1.26488 12.6727 1.61162ZM14 11H23V18H14V11ZM16 13V16H21V13H16ZM24 21H13V19H24V21Z"></path>
                                        </svg>
                                      </span>
                                      {data?.spaceId?.name}
                                    </span>
                                    {` > `}
                                    <span className="fs-7">
                                      <span className="initial-wrap me-1">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          width="15"
                                          height="15"
                                          className="mb-1"
                                        >
                                          <path
                                            d="M21 9V20.9925C21 21.5511 20.5552 22 20.0066 22H3.9934C3.44495 22 3 21.556 3 21.0082V2.9918C3 2.45531 3.44694 2 3.99826 2H14V8C14 8.55228 14.4477 9 15 9H21ZM21 7H16V2.00318L21 7ZM8 7V9H11V7H8ZM8 11V13H16V11H8ZM8 15V17H16V15H8Z"
                                            fill="currentColor"
                                          ></path>
                                        </svg>
                                      </span>
                                      {data?.name}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            )}
                            {!loader2 && filteredLists?.length === 0 && (
                              <span className="d-flex text-center mt-1">
                                No Sub Role found
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row gx-3 mt-2">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label className="form-label">Name</label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          onChange={(e) => {
                            settaskName(e.target.value);
                          }}
                          value={taskName}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row gx-3">
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">Note/Description</label>
                          <small className="text-muted">200</small>
                        </div>
                        <textarea
                          className="form-control"
                          rows="3"
                          onChange={(e) => {
                            settaskdescription(e.target.value);
                          }}
                          value={taskdescription}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row gx-3">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label">Due Date</label>
                        <input
                          className="form-control"
                          name="single-date-pick"
                          type="date"
                          value={dueDate}
                          onChange={(e) => {
                            setdueDate(e.target.value);
                          }}
                          min={date}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row gx-3">
                    <div className="col-sm-12">
                      <div className="form-inline">
                        <div className="form-group">
                          <label className="form-label">Set priority:</label>
                          <div className="form-check form-check-inline ms-2">
                            <div className="custom-control custom-radio radio-primary">
                              <input
                                type="radio"
                                id="customRadioc2"
                                name="customRadioc2"
                                className="form-check-input pointer"
                                value="HIGH"
                                onChange={handlePriorityChange}
                                checked={priority === "HIGH"}
                              />
                              <label className="form-check-label">High</label>
                            </div>
                          </div>
                          <div className="form-check form-check-inline">
                            <div className="custom-control custom-radio radio-primary">
                              <input
                                type="radio"
                                id="customRadioc3"
                                name="customRadioc2"
                                className="form-check-input pointer"
                                value="MEDIUM"
                                onChange={handlePriorityChange}
                                checked={priority === "MEDIUM"}
                              />
                              <label className="form-check-label">Medium</label>
                            </div>
                          </div>
                          <div className="form-check form-check-inline">
                            <div className="custom-control custom-radio radio-primary">
                              <input
                                type="radio"
                                id="customRadioc4"
                                name="customRadioc2"
                                className="form-check-input pointer"
                                value="LOW"
                                onChange={handlePriorityChange}
                                checked={priority === "LOW"}
                              />
                              <label className="form-check-label">Low</label>
                            </div>
                          </div>
                          <div className="form-check form-check-inline">
                            <div className="custom-control custom-radio radio-primary">
                              <input
                                type="radio"
                                id="customRadioc4"
                                name="customRadioc2"
                                className="form-check-input pointer"
                                value="NO_PRIORITY"
                                onChange={handlePriorityChange}
                                checked={priority === "NO_PRIORITY"}
                              />
                              <label className="form-check-label">
                                No Priority
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="title title-xs title-wth-divider text-primary text-uppercase mt-2">
                    <span>Assign to</span>
                  </div>
                  <div className="repeater">
                    <div data-repeater-list="category-group">
                      <ul className="hk-list pointer">
                        {selectedEmployees?.map((emp, index) => {
                          return (
                            <CustomTooltip
                              text={emp?.name}
                              placement={index === 0 ? "right" : "bottom"}
                            >
                              <li key={index}>
                                <Avatar
                                  name={emp?.name}
                                  count={1}
                                  image={emp?.image}
                                  size="xs"
                                />
                              </li>
                            </CustomTooltip>
                          );
                        })}
                        {listId ? (
                          <li
                            data-bs-toggle="modal"
                            data-bs-target="#select_employee"
                            onClick={() => {
                              setisSelectedEmployeeModal(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              fill="currentColor"
                              class="bi bi-plus-circle"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                          </li>
                        ) : (
                          <li
                            onClick={() => {
                              toast.error(
                                "You need to select a list first to add assignee"
                              );
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              fill="currentColor"
                              class="bi bi-plus-circle"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <div className="modal-footer align-items-center">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleReset}
                  >
                    Discard
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loader ? <ApiLoader /> : "Add"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <SelectEmployeeModal
        setisSelectedEmployeeModal={setisSelectedEmployeeModal}
        isSelectedEmployeeModal={isSelectedEmployeeModal}
        listId={listId}
        user={user}
        selectedEmployees={selectedEmployees}
        setselectedEmployees={setselectedEmployees}
        selectedEmpId={selectedEmpId}
        setselectedEmpId={setselectedEmpId}
      />
    </>
  );
};

export default AddTaskInMyTasks;
