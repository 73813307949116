import React, { useState, useEffect } from "react";
import { Avatar } from "../../components/Avatar/Avatar";
import { startApiCall } from "../../global-functions/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { invitationWorkspaceEndPoints } from "../../api/Endpoints";
import DataLoader from "../../components/Loaders/DataLoader/DataLoader";
import TokenExpired from "./TokenExpired";
import SuccessfullyJoined from "./SuccessfullyJoined";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../components/Error/ErrorMessage";
import { useSelector } from "react-redux";

const JoinWorkspaceFromInvitation = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user);
  const [loader, setLoader] = useState(true);
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");
  const businessId = queryParameters.get("businessId");
  const [user, setUser] = useState(null);
  const [errorStatus, seterrorStatus] = useState();
  const [isSuccess, setisSuccess] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  const verifyInvitations = async () => {
    startApiCall(null, setLoader);
    const res = await ApiCall(
      "get",
      `${invitationWorkspaceEndPoints.verifyInvite}?token=${token}&businessId=${businessId}&employeeId=${currentUser?._id}`
    );
    if (res?.success) {
      if (res.user?.token) {
        setUser(res.user);
        setLoader(false);
      } 
  
    } else {
      seterrorStatus(res.status);
      setLoader(false);
    }
  };

  const handleApproveOrRejectWorkspace = async (isApproved) => {
    startApiCall(seterrorMessage, setLoader);
    const data = {
      isApproved: isApproved,
      email: currentUser?.email,
      businessId,
      employeeId: currentUser?._id,
      token,
    };
    const res = await ApiCall(
      "post",
      invitationWorkspaceEndPoints.approveorRejectWorkspaceRequest,
      data
    );
    if (res?.success) {
      if (!isApproved) {
        navigate("/login");
      } else {
        setisSuccess(true);
      }
      setLoader(false);
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  useEffect(() => {
    verifyInvitations();
  }, [token]);

  return (
    <div className="workspace-container p-0">
      {!loader && user?.business && !isSuccess && (
        <>
          <span className="icon">
            <span className="position-relative">
              <span className="feather-icon">
                <div className="media-head mb-5">
                  <Avatar
                    name={user?.business?.name}
                    count={1}
                    image={user?.business?.image}
                    size="lg"
                    color="primary"
                  />
                </div>
              </span>{" "}
            </span>
          </span>
          <h2 className="workspace-name">Join {user?.business?.name}</h2>
          {errorMessage && <ErrorMessage errorMessage={errorMessage} />}

          <div className="button-container">
            <button
              onClick={() => {
                handleApproveOrRejectWorkspace(false);
              }}
              className=" reject-button"
            >
              Not Interested
            </button>
            <button
              onClick={() => {
                handleApproveOrRejectWorkspace(true);
              }}
              className=" accept-button"
            >
              Join Company
            </button>
          </div>
        </>
      )}
      {errorStatus && <TokenExpired />}
      {errorStatus !== 401 && isSuccess && (
        <SuccessfullyJoined businessName={user?.business?.name} />
      )}

      {loader && <DataLoader />}
    </div>
  );
};

export default JoinWorkspaceFromInvitation;
