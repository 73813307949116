import { useEffect, useState } from "react";
import ApiCall from "../../api/ApiCall";
import { businessEndpoints } from "../../api/Endpoints";
import ErrorMessage from "../../components/Error/ErrorMessage";
import ApiLoader from "../../components/Loaders/ApiLoader/ApiLoader";
import { startApiCall } from "../../global-functions/globalFunctions";
import { toast } from "react-toastify";
import { Avatar } from "../../components/Avatar/Avatar";

const JoinWorkspace = (props) => {
  const { isOpenJoinWorkspaceModal, setisOpenJoinWorkspaceModal, user } = props;

  const [uniqueId, setUniqueId] = useState("");
  const [loader, setLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [loader2, setLoader2] = useState(false);
  const [searchedWorkspace, setsearchedWorkspace] = useState([]);

  const endApiCall = () => {
    setLoader2(false);
    setsearchedWorkspace([]);
    setUniqueId("");
    seterrorMessage("");
    setLoader(false);
    setisOpenJoinWorkspaceModal(false);
  };

  const handleJoinBusiness = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    const data = {
      uniqueId: uniqueId.trim(),
      employeeId: user?._id,
    };
    const res = await ApiCall("post", businessEndpoints.joinBusiness, data);
    if (res?.success) {
      toast.success("Join Business Request Send successfully!");
      endApiCall();
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  const handleSearchWorkspace = async () => {
    startApiCall(seterrorMessage, setLoader2);
    const data = {
      employeeId: user?._id,
      filters: {
        businessUniqueId: uniqueId.trim(),
      },
    };
    const res = await ApiCall("post", businessEndpoints.searchBusiness, data);
    if (res?.success) {
      // console.log("res", res);
      setsearchedWorkspace(res?.business);
      setLoader2(false);
    } else {
      seterrorMessage(res.error);
      setLoader2(false);
    }
  };

  useEffect(() => {
    if (uniqueId?.length > 4) {
      handleSearchWorkspace();
    }
  }, [uniqueId]);

  return (
    <div
      id="join_workspace"
      className={`modal fade add-new-contact mt-10 ${
        isOpenJoinWorkspaceModal ? "show" : ""
      }`}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: isOpenJoinWorkspaceModal ? "block" : "none" }}
    >
      <form onSubmit={handleJoinBusiness}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="btn-close" onClick={endApiCall}>
                <span aria-hidden="true">x</span>
              </button>
              <h5 className="mb-4">Join Company</h5>
              <div className="row gx-3">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Unique code</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter unique code"
                      required
                      value={uniqueId}
                      onChange={(event) => setUniqueId(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div>
                {loader2 && (
                  <p className="text-center">Searching workspace...</p>
                )}
              </div>
              {!loader2 && searchedWorkspace?.length > 0 && (
                <div className="card card-border note-block bg-primary-light-5">
                  <div className="card-body text-center text-primary fw-light">
                    <div className="d-flex justify-content-center me-1">
                      <Avatar
                        name={searchedWorkspace?.[0]?.name}
                        count={1}
                        image={searchedWorkspace?.[0]?.image}
                        size="sm"
                        color="primary"
                      />
                      <div>
                        <span className="d-block link-dark fw-medium">
                          {searchedWorkspace?.[0]?.name}
                        </span>
                        <div className="fs-7 ms-3">
                          {searchedWorkspace?.[0]?.city},
                          {searchedWorkspace?.[0]?.state}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!loader2 &&
                searchedWorkspace?.length === 0 &&
                uniqueId?.length > 0 && (
                  <div className="card card-border note-block bg-danger-light-5">
                    <div className="card-body">
                      <p>No Workspace Found</p>
                    </div>
                  </div>
                )}
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            </div>
            <div className="modal-footer align-items-center">
              <button
                type="button"
                disabled={loader}
                className="btn btn-secondary"
                onClick={endApiCall}
              >
                Cancel
              </button>
              <button
                disabled={loader || searchedWorkspace?.length === 0}
                type="submit"
                className="btn btn-primary"
              >
                {loader ? <ApiLoader /> : "Join"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default JoinWorkspace;
