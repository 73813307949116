import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getAllEmployees,
  getAvatarClass,
  getCurrentWorkspaceId,
  getCreatorForIndividualModule,
  getWorkSpaceRole,
  isEmployeeOwner,
  startApiCall,
  filterSearchData,
} from "../../global-functions/globalFunctions";
import { Avatar } from "../Avatar/Avatar";
import DataLoader from "../Loaders/DataLoader/DataLoader";

function PermissionPage(props) {
  const user = useSelector((state) => state.user);
  const {
    makePrivate,
    selectedEmployees,
    setSelectedEmployees,
    setmakePrivate,
    infoData,
    type,
    searchKey,
    setsearchKey,
    setErrorMessage,
    closeModal,
    removedEmployee,
    setremovedEmployee,
    isAddList,
    isAddFolder,
  } = props;
  const [allEmployees, setallEmployees] = useState([]);
  const [loader, setloader] = useState(false);

  const filteredEmployees = filterSearchData(allEmployees, searchKey);

  const handleGetAllEmployee = async () => {
    console.log("called from permission--------");
    startApiCall(null, setloader);
    let data = {};
    if (type === "space") {
      data = {
        pageLimit: 50000,
        pageNumber: 1,
        s2adminId: user?._id,
        searchKey: searchKey,
        businessId: getCurrentWorkspaceId(),
      };
    } else if (type === "folder") {
      data = {
        pageLimit: 50000,
        pageNumber: 1,
        s2adminId: user?._id,
        searchKey: searchKey,
        businessId: getCurrentWorkspaceId(),
        accessFor: "forFolderAccess",
        spaceId: isAddFolder ? infoData : infoData?.spaceId,
      };
    } else if (type === "addListIntoFolder") {
      data = {
        pageLimit: 50000,
        pageNumber: 1,
        s2adminId: user?._id,
        searchKey: searchKey,
        businessId: getCurrentWorkspaceId(),
        accessFor: "forFolderListCreate",
        folderId: infoData,
      };
    } else if (type === "list") {
      data = {
        pageLimit: 50000,
        pageNumber: 1,
        s2adminId: user?._id,
        searchKey: searchKey,
        businessId: getCurrentWorkspaceId(),
        accessFor: "forListAccess",
        listId: infoData?._id,
      };
    }
    const res = await getAllEmployees(data);
    setallEmployees(res?.data);
    setloader(false);
  };

  useEffect(() => {
    if (makePrivate) {
      handleGetAllEmployee();
    }
  }, [infoData, makePrivate]);

  const toggleEmployeeSelection = (employeeId) => {
    setErrorMessage && setErrorMessage("");
    if (selectedEmployees?.includes(employeeId)) {
      selectedEmployees?.splice(selectedEmployees?.indexOf(employeeId), 1);
      setSelectedEmployees([...selectedEmployees]);
      removedEmployee?.push(employeeId);
    } else if (!selectedEmployees?.includes(employeeId)) {
      selectedEmployees?.push(employeeId);
      setSelectedEmployees([...selectedEmployees]);
      if (removedEmployee?.length > 0) {
        removedEmployee?.splice(removedEmployee?.indexOf(employeeId), 1);
        setremovedEmployee([...removedEmployee]);
      }
    }
  };

  useEffect(() => {
    if (infoData && makePrivate) {
        if (type === "space" && infoData.privateSpaceAccess?.length > 0) {
          setSelectedEmployees([...infoData.privateSpaceAccess]);
        } else if (
          type === "folder" &&
          infoData.privateFolderAccess?.length > 0
        ) {
          setSelectedEmployees([...infoData.privateFolderAccess]);
        } else if (type === "list" && infoData.privateListAccess?.length > 0) {
          setSelectedEmployees([...infoData.privateListAccess]);
        }
    }
  }, [infoData, closeModal, makePrivate]);

  useEffect(() => {
    setmakePrivate(infoData?.isPrivate);
  }, [infoData]);

  const getEmployeeData = () => {
    let temp = [];
    if (makePrivate) {
      selectedEmployees?.map((ae) => {
        allEmployees?.map((emp) => {
          if (emp?._id === ae) {
            temp.push(emp);
          }
        });
      });
    } else {
      allEmployees?.map((emp) => {
        temp.push(emp);
      });
    }

    return temp;
  };

  const makePublic = () => {
    setErrorMessage && setErrorMessage("");
    setsearchKey("");
    setmakePrivate(false);
  };
  return (
    <div>
      <div className="title title-sm title-wth-divider text-primary text-uppercase my-2">
        <span>Share With</span>
      </div>

      <div className="row mt-2">
        <div className="col-sm-8 d-flex align-items-center justify-content-start">
          <button
            type="button"
            className={`btn space-button ${
              !makePrivate ? "selected-permission" : ""
            }`}
            onClick={makePublic}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="12"
              fill="currentColor"
              className="bi bi-unlock"
              viewBox="0 0 16 16"
              stroke-width="25"
            >
              <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z" />
            </svg>{" "}
            Public
          </button>
          <button
            type="button"
            className={`btn space-button ms-1 ${
              makePrivate ? "selected-permission" : ""
            }`}
            onClick={() => {
              setmakePrivate(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="12"
              fill="currentColor"
              className="bi bi-lock"
              viewBox="0 0 16 16"
              stroke-width="25"
            >
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
            </svg>{" "}
            Private
          </button>
        </div>
        {makePrivate && (
          <div className="col-sm-4 d-flex justify-content-end align-items-center">
            <span className="input-affix-wrapper input-search affix-border">
              <input
                type="text"
                className="form-control bg-transparent"
                data-navbar-search-close="false"
                placeholder="Search People"
                aria-label="Search"
                value={searchKey}
                onChange={(e) => {
                  setErrorMessage && setErrorMessage("");
                  setsearchKey(e.target.value);
                }}
                style={{ width: "15vw" }}
              />
              <span className="input-suffix">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </span>
              </span>
            </span>
          </div>
        )}
      </div>

      <div className="d-flex justify-content-between">
        {makePrivate && (
          <small className="ms-1 mt-1">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="9"
              fill="currentColor"
              className="bi bi-lock"
              viewBox="0 0 16 16"
            >
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
            </svg>{" "}
            only members you add to the department can view
          </small>
        )}
        {!makePrivate && (
          <small className="ms-1 mt-1">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="9"
              fill="currentColor"
              class="bi bi-unlock"
              viewBox="0 0 16 16"
            >
              <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z" />
            </svg>{" "}
            all members in the department can view
          </small>
        )}
      </div>

      <div className="collapse-simple">
        <div className="card">
          <div className="card-header">
            <span
              role="button"
              data-bs-toggle="collapse"
              href="#space_members"
              aria-expanded="true"
              className="fw-light text-uppercase"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-people-fill"
                  viewBox="0 2 16 16"
                >
                  <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                </svg>{" "}
                People
                {makePrivate && (
                  <span class="badge badge-pill badge-sm badge-soft-primary ms-1">
                    {allEmployees?.length}
                  </span>
                )}
              </span>

              {makePrivate && (
                <div className="avatar-group avatar-group-sm avatar-group-overlapped me-6 pointer">
                  {getEmployeeData()
                    ?.slice(0, 4)
                    ?.map((emp, index) => {
                      let name = emp?.name;
                      const nameInitial = name?.[0]?.toUpperCase();
                      const avatarClass = getAvatarClass(nameInitial);
                      return (
                        <div
                          key={index}
                          className={`avatar avatar-xs ${avatarClass} avatar-rounded`}
                        >
                          <span className="initial-wrap">{nameInitial}</span>
                        </div>
                      );
                    })}
                  {getEmployeeData()?.length > 4 && (
                    <div className="avatar avatar-xs avatar-soft-indigo avatar-rounded">
                      <span className="initial-wrap">
                        +{getEmployeeData().length - 4}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </span>
          </div>

          <div
            id="space_members"
            className="collapse show mh-200p "
            style={{ overflowY: "scroll", overflowX: "hidden" }}
          >
            <div className="card-body">
              {!loader && filteredEmployees?.length > 0 && makePrivate && (
                <ul className="activity-list list-group list-group-flush">
                  {filteredEmployees?.map((employee) => {
                    return (
                      <li className="list-group-item" key={employee.id}>
                        <div className="media">
                          <div className="media-head">
                            <Avatar
                              name={employee?.name}
                              count={1}
                              image={employee?.image}
                              size="xss"
                              color="primary"
                            />
                          </div>
                          <div className="media-body row">
                            <div className="d-flex justify-content-between">
                              <span
                                className="text-dark text-truncate"
                                style={{
                                  textTransform: "capitalize",
                                  width: "200px",
                                }}
                              >
                                {employee.name}
                              </span>

                              <div>
                                <span className={`badge badge-soft-brown `}>
                                  {getCreatorForIndividualModule(
                                    infoData,
                                    employee?._id,
                                    type
                                  )
                                    ? "Creator"
                                    : ""}
                                </span>
                                &nbsp;&nbsp;
                                <span className={`badge badge-soft-primary`}>
                                  {getWorkSpaceRole(
                                    employee?.permissions,
                                    "roleName"
                                  )}
                                </span>
                                &nbsp;&nbsp;
                                <input
                                  className="form-check-input success pointer me-3"
                                  type="checkbox"
                                  value=""
                                  disabled={
                                    isEmployeeOwner(employee?.permissions) ||
                                    (!isEmployeeOwner(user?.permissions) &&
                                      getCreatorForIndividualModule(
                                        infoData,
                                        employee?._id,
                                        type
                                      )) ||
                                    user?._id === employee?._id
                                  }
                                  onChange={() =>
                                    toggleEmployeeSelection(employee?._id)
                                  }
                                  checked={
                                    isEmployeeOwner(employee?.permissions) ||
                                    selectedEmployees?.includes(employee?._id)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
              {!loader && filteredEmployees?.length === 0 && makePrivate && (
                <p className="text-capitalize text-center text-dark">
                  No employee found
                </p>
              )}

              {!makePrivate && (
                <div class="card card-border note-block bg-success-light-5">
                  <div class="card-body">
                    <p>
                      This {isAddList ? "LIST" : type?.toUpperCase()} is visible
                      to all the members in Company
                    </p>
                  </div>
                </div>
              )}
              {loader && <DataLoader />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PermissionPage;
