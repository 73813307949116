import React from "react";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import NoData from "../../../../components/NoData/NoData";

const EmployeeTaskGraph = ({ taskCount }) => {
  const isNoData = Object.values(taskCount).every((value) => value === 0);
  const doughnutData = {
    labels: ["All", "Assigned", "Unassigned", "Scheduled", "Unscheduled"],
    datasets: [
      {
        label: "No. of Task",
        data: [
          taskCount?.all,
          taskCount?.assigned,
          taskCount?.all - taskCount?.assigned,
          taskCount?.scheduled,
          taskCount?.all - taskCount?.scheduled,
        ],
        backgroundColor: [
          "#008492",
          "#77dd77",
          "#DDF1FC",
          "#FFCE56",
          "#FF0000",
        ],
        borderColor: ["#008492", "#77dd77", "#DDF1FC", "#FFCE56", "#FF0000"],
        borderWidth: 1,
      },
    ],
    options: {
      responsive: true,
      plugins: {
        datalabels: false, // Removing this line shows the datalabels again
      },
    },
  };

  return (
    <div className="border p-3 rounded-4 d-flex flex-column gap-3">
      {isNoData ? (
        <NoData />
      ) : (
        <div className="mx-auto">
          <Chart type="doughnut" data={doughnutData} />
        </div>
      )}
      <div className="d-flex flex-column gap-1">
        <div className="d-flex align-items-center justify-content-between">
          <span className="d-flex align-items-center gap-2 text-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#008492"
              className="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
            <p className="text-semibold text-dark fs-7">All</p>
          </span>
          <p className=" fs-7 text-dark">
            {taskCount?.all ? taskCount?.all : 0}
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <span
            className="d-flex align-items-center gap-2"
            style={{ color: "#DDF1FC" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#77dd77"
              className="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
            <p className="text-semibold text-dark fs-7">Assigned</p>
          </span>
          <p className=" fs-7 text-dark">
            {taskCount?.assigned ? taskCount?.assigned : 0}
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <span className="d-flex align-items-center gap-2 text-danger">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#DDF1FC"
              className="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
            <p className="text-semibold text-dark fs-7">Unassigned</p>
          </span>
          <p className=" fs-7 text-dark">
            {taskCount?.all - taskCount?.assigned
              ? taskCount?.all - taskCount?.assigned
              : 0}
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <span className="d-flex align-items-center gap-2 text-warning">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#FFCE56"
              className="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
            <p className="text-semibold text-dark fs-7">Scheduled</p>
          </span>
          <p className=" fs-7 text-dark">
            {taskCount?.scheduled ? taskCount?.scheduled : 0}
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <span className="d-flex align-items-center gap-2 text-danger">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#FF0000"
              className="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
            <p className="text-semibold text-dark fs-7">Unscheduled</p>
          </span>
          <p className=" fs-7 text-dark">
            {taskCount?.all - taskCount?.scheduled
              ? taskCount?.all - taskCount?.scheduled
              : 0}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmployeeTaskGraph;
