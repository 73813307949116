import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { graphEndPoints } from "../../../api/Endpoints";
import { useSelector } from "react-redux";
import TaskActivity from "../TaskActivity";
import ListDetails from "./ListDetails";
import NoData from "../../../components/NoData/NoData";
import Pagination from "../../../components/Pagination/Pagination";
import SmallCalendar from "../../../components/SmallCalendar/SmallCalendar";
import UserInfoModal from "../../../components/UserInfoModal/UserInfoModal";
import MonthlyTaskGraph from "./Graphs/MonthlyTaskGraph";
import EmployeeTaskGraph from "./Graphs/EmployeeTaskGraph";
import TaskCountCard from "./TaskCountCard";
import PollCard from "./PollCard";
import MeetingCard from "./MeetingCard";
import NoticeCard from "./NoticeCard";
import SkeletonConstant from "../../../components/Constant/SkeletonConstant";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";

const DashboardBody = ({ activeTab }) => {
  const { user, allModulePermission } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [listTaskData, setlistTaskData] = useState([]);
  const [counts, setCounts] = useState("");
  const [page, setPage] = useState(1);
  const [taskCount, setTaskCount] = useState({});
  const [pageLimit, setpageLimit] = useState(10);
  const [totalPages, settotalPages] = useState(0);

  const handleCheckModulePermission = (moduleName) => {
    let isPublic = false;
    isPublic = allModulePermission?.includes(moduleName);
    return isPublic;
  };

  // ****** FOR USER DETILS ******
  const [userInfoData, setuserInfoData] = useState([]);

  const handleTaskStatusGraph = async (e) => {
    startApiCall(null, setloader);
    const res = await ApiCall("get", graphEndPoints.listTaskStatusGraph, {
      params: {
        businessId: getCurrentWorkspaceId(),
        showAll: true,
        pageNumber: page,
        pageLimit: pageLimit,
        employeeId: user?._id,
      },
    });
    if (res?.success) {
      setlistTaskData(res?.result?.results);
      setCounts(res?.result?.totalCounts);
      settotalPages(res?.result?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  const handleGetTaskCounts = async () => {
    const sendData = {
      employeeId: user?._id,
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall(
      "post",
      graphEndPoints.employeeTotalTasks,
      sendData
    );
    if (res?.success) {
      setTaskCount(res?.data);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleTaskStatusGraph();
      handleGetTaskCounts();
    }
  }, [page, pageLimit]);

  // const cardSkeleton = (
  //   <div className="card p-3 loading-skeleton gap-2 w-100">
  //     <p className="bg-skeleton h-30p w-100"></p>
  //     <p className="bg-skeleton h-30p w-25"></p>
  //     <p className="bg-skeleton h-30p w-50"></p>
  //   </div>
  // );

  return (
    <>
      <div className="hk-pg-body p-0">
        <div className="tab-content">
          <div
            className={`tab-pane fade ${
              activeTab === "tab_block_1" ? "show active" : ""
            } `}
            id="tab_block_1"
          >
            <div className="row">
              <div className="col-md-12 mb-md-4">
                <div className="card card-border mb-0 h-100">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6 mb-md-3 mb-sm-3 mb-lg-0">
                        {!loader && (
                          <div className="border rounded-4 p-3">
                            <h5>Tasks Overview</h5>
                            <div className="flex-grow-1">
                              {getCurrentWorkspaceId() ? (
                                <TaskCountCard taskCount={taskCount} />
                              ) : (
                                <NoData title="No Company found" />
                              )}
                            </div>
                          </div>
                        )}
                        {loader && (
                          <div className="loading-skeleton card p-3">
                            <h5 className="bg-skeleton w-250p h-40p"></h5>
                            <div className="card loading-skeleton p-3">
                              <div className="d-flex justify-content-between gap-3">
                                <SkeletonConstant
                                  width="w-80p"
                                  height="h-30p"
                                />
                                <SkeletonConstant
                                  width="w-80p"
                                  height="h-30p"
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between gap-3">
                              <div className="card p-3 loading-skeleton gap-2 w-100">
                                <SkeletonConstant
                                  width="w-100"
                                  height="h-30p"
                                />
                                <SkeletonConstant width="w-25" height="h-30p" />
                                <SkeletonConstant width="w-50" height="h-30p" />
                              </div>
                              <div className="card p-3 loading-skeleton gap-2 w-100">
                                <SkeletonConstant
                                  width="w-100"
                                  height="h-30p"
                                />
                                <SkeletonConstant width="w-25" height="h-30p" />
                                <SkeletonConstant width="w-50" height="h-30p" />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        {!loader && <SmallCalendar />}
                        {loader && (
                          <div className="loading-skeleton w-100 card p-3 gap-2">
                            <SkeletonConstant
                              width="w-85"
                              height="h-30p"
                              additional="mx-auto"
                            />
                            <SkeletonConstant
                              width="w-70"
                              height="h-40p"
                              additional="mx-auto"
                            />
                            <SkeletonConstant
                              width="w-100"
                              height="h-40p"
                              additional="mx-auto"
                            />
                            <div className="bg-skeleton w-100 h-200p"></div>
                            <div className="card py-1 loading-skeleton">
                              <SkeletonConstant
                                width="w-70"
                                height="h-30p"
                                additional="mx-auto"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {handleCheckModulePermission(PRIVATEROUTES.MEETING) && (
              <div className="row">
                <div className="col-12">
                  <div className="card card-border">
                    <MeetingCard />
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              {handleCheckModulePermission(PRIVATEROUTES.NOTICE) && (
                <div className="col-md-6">
                  <NoticeCard />
                </div>
              )}
              {handleCheckModulePermission(PRIVATEROUTES.POLL) && (
                <div className="col-md-6">
                  <PollCard />
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-12 mb-md-4 mb-3">
                <div className="card card-border mb-0 h-100">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-8 col-md-12">
                        <MonthlyTaskGraph user={user} />
                      </div>
                      <div className="col-lg-4 col-md-12">
                        {!loader && <EmployeeTaskGraph taskCount={taskCount} />}
                        {loader && (
                          <div className="card loading-skeleton w-100 p-3 gap-3">
                            <SkeletonConstant
                              width="w-80"
                              height="h-300p"
                              additional="mx-auto"
                            />
                            <div className="d-flex flex-column gap-1">
                              <SkeletonConstant width="w-100" height="h-25p" />
                              <SkeletonConstant width="w-100" height="h-25p" />
                              <SkeletonConstant width="w-100" height="h-25p" />
                              <SkeletonConstant width="w-100" height="h-25p" />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12 mb-md-4 mb-3">
                <div className="card card-border mb-0">
                  {!loader && (
                    <div className="card-header card-header-action">
                      <h6>
                      Sub Role Details
                        <span className="badge badge-sm badge-light ms-1">
                          {counts}
                        </span>
                      </h6>
                      {getCurrentWorkspaceId() && (
                        <span
                          className="btn-icon-wrap pointer"
                          onClick={handleTaskStatusGraph}
                        >
                          <span className="feather-icon">
                            <i
                              className="ri-refresh-line"
                              title="Refresh business list"
                            ></i>
                          </span>
                        </span>
                      )}
                    </div>
                  )}
                  {loader && (
                    <div className="card-header card-header-action loading-skeleton d-flex justify-content-between">
                      <SkeletonConstant width="w-150p" height="h-25p" />
                      <SkeletonConstant width="w-50p" height="h-25p" />
                    </div>
                  )}
                  <div
                    className="card-body"
                    style={{ overflow: "auto", maxHeight: "100vh" }}
                  >
                    <div className="contact-list-view">
                      {!loader && listTaskData?.length > 0 && (
                        <ListDetails
                          listTaskData={listTaskData}
                          page={page}
                          pageLimit={pageLimit}
                          setuserInfoData={setuserInfoData}
                        />
                      )}
                      {loader && (
                        <div className="loading-skeleton p-3 w-100">
                          <SkeletonConstant width="w-100" height="h-600p" />
                        </div>
                      )}
                      {!loader && listTaskData?.length === 0 && (
                        <NoData title="No Sub Role found" />
                      )}
                    </div>
                  </div>
                </div>

                {!loader && listTaskData?.length > 0 && (
                  <div className="mt-3 mb-8">
                    <Pagination
                      pageLimit={pageLimit}
                      setpageLimit={setpageLimit}
                      page={page}
                      setPage={setPage}
                      totalPages={totalPages}
                      count={counts}
                      dataLength={listTaskData?.length}
                      align={"end"}
                    />
                  </div>
                )}
              </div>
            </div>
            <UserInfoModal user={userInfoData} />
          </div>
          <div
            className={`tab-pane fade ${
              activeTab === "tab_block_2" ? "show active" : ""
            } `}
            id="tab_block_2"
          >
            <TaskActivity activeTab={activeTab} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardBody;
