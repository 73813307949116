import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { leaveRelatedEndPoints } from "../../../../api/Endpoints";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../../components/Error/ErrorMessage";
import { toast } from "react-toastify";

const AddType = ({
  handleGetAllLeaveTypes,
  editLeaveType,
  setEditLeaveType,
}) => {
  const [leaveTypes, setLeaveTypes] = useState("");
  const [allowedDays, setAllowedDays] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setloader] = useState(false);

  const handleReset = () => {
    setErrorMessage("");
    setloader(false);
    setLeaveTypes("");
    setAllowedDays("");
    setEditLeaveType("");
    const closeModalDom = document.getElementById("add_leave_types");
    if (closeModalDom) closeModalDom.click();
  };

  const handleAddLeaveType = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      businessId: getCurrentWorkspaceId(),
      leaveName: leaveTypes,
      allowedDays: allowedDays,
    };
    const res = await ApiCall(
      "post",
      leaveRelatedEndPoints.createLeaveTypes,
      sendData
    );
    if (res?.success) {
      handleReset();
      toast.success("Create leave type successfully!");
      handleGetAllLeaveTypes();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  const handleEditLeaveType = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      leaveTypeId: editLeaveType._id,
      leaveName: leaveTypes,
      allowedDays: allowedDays,
    };
    const res = await ApiCall(
      "post",
      leaveRelatedEndPoints.editLeaveTypes,
      sendData
    );
    if (res?.success) {
      toast.success("Leave type edited successfully!");
      handleReset();
      handleGetAllLeaveTypes();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  useEffect(() => {
    if (editLeaveType) {
      setLeaveTypes(editLeaveType.leaveName);
      setAllowedDays(editLeaveType.allowedDays);
    }
  }, [editLeaveType]);

  return (
    <div
      id="add_leave_types"
      className="modal fade add-new-task"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>{editLeaveType !== "" ? "Edit Type" : "Add Type"}</h5>
            <div className="border-bottom mb-2"></div>
            <form
              onSubmit={(e) => {
                if (editLeaveType !== "") {
                  handleEditLeaveType(e);
                } else {
                  handleAddLeaveType(e);
                }
              }}
            >
              <div className="d-flex justify-content-center mt-2">
                <div
                  className="alert alert-info text-primary fs-7"
                  role="alert"
                >
                  <strong>Note:</strong> The leave type should be applied annually to
                  each members in the company. You can't modify allowed days
                  once added.
                </div>
              </div>
              <div className="row gx-3 mt-1">
                <div className="col-sm-7">
                  <div className="form-group">
                    <label className="form-label">Type Name </label>
                    <input
                      className="form-control"
                      type="text"
                      value={leaveTypes}
                      onChange={(e) => setLeaveTypes(e.target.value)}
                      placeholder="Enter leave type"
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-5">
                  <div className="form-group">
                    <label className="form-label">Allowed Days </label>
                    <input
                      className="form-control"
                      type="number"
                      value={allowedDays}
                      onChange={(e) => setAllowedDays(e.target.value)}
                      placeholder="Enter no. of days"
                      required
                      disabled={editLeaveType}
                    />
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  {loader ? <ApiLoader /> : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddType;
