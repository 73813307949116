import React from "react";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";

const DetailsLeft = (props) => {
  const {
    taskDetails,
    allSubTask,
    setisEdited,
    seteditedTaskOrSubTask,
    comment,
    setcomment,
    handleAddComment,
    seterrorMessage,
    taskEditLoader,
    isSubTaskAdd,
    setisSubTaskAdd,
    setsubTaskName,
    subTaskName,
    handleCreateSubtask,
    subTaskLoader,
    handleDeleteSubTask,
    pleaseWait,
    setIsEditDescription,
  } = props;

  const convertTextToLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const newlineRegex = /\n/g;

    if (text) {
      text = text.replace(urlRegex, (url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });

      text = text.replace(newlineRegex, "<br>");
    }

    return text;
  };
  return (
    <div className="w-50 d-flex flex-column gap-3 simplebar-hide-scrollbar py-2 px-4 ">
      <div className={pleaseWait ? "" : " d-flex align-items-center gap-1 "}>
        <span
          className="fs-4 text-dark simple-scrollbar pt-1"
          style={{ overflow: "scroll", maxHeight: "100px" }}
        >
          {taskDetails?.name}
        </span>
        <span className="btn btn-sm btn-icon btn-rounded btn-flush-dark flush-soft-hover">
          <span className="feather-icon">
            <svg
              onClick={() => {
                seterrorMessage("");
                seteditedTaskOrSubTask(taskDetails);
                setisEdited(true);
              }}
              className="pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-pencil-square"
              viewBox="0 0 16 16"
            >
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
          </span>
        </span>
      </div>

      <div className="d-flex align-items-center gap-1">
        <span
          className="font-3 mh-100p simple-scrollbar pt-1"
          style={{ overflow: "scroll" }}
          dangerouslySetInnerHTML={{
            __html: convertTextToLinks(taskDetails?.description),
          }}
        ></span>
        {taskDetails?.description ? (
          <span className="btn btn-sm btn-icon btn-rounded btn-flush-dark flush-soft-hover">
            <span className="feather-icon">
              <svg
                onClick={() => {
                  seterrorMessage("");
                  seteditedTaskOrSubTask(taskDetails);
                  setisEdited(true);
                  setIsEditDescription(true);
                }}
                className="pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-pencil-square"
                viewBox="0 0 16 16"
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                />
              </svg>
            </span>
          </span>
        ) : (
          <span
            className="text-primary pointer"
            onClick={() => {
              seterrorMessage("");
              seteditedTaskOrSubTask(taskDetails);
              setisEdited(true);
              setIsEditDescription(true);
            }}
          >
            Add Description
          </span>
        )}
      </div>

      <div className="d-flex flex-column gap-1">
        <textarea
          className="form-control border-1"
          placeholder={`Write some comment`}
          value={comment}
          onChange={(e) => {
            setcomment(e.target.value);
          }}
        ></textarea>
        <div className="d-flex justify-content-end">
          <button
            disabled={comment === "" || taskEditLoader}
            onClick={handleAddComment}
            className="btn btn-outline-light btn-sm "
          >
            {taskEditLoader ? (
              <ApiLoader />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#008492"
                class="bi bi-send-fill"
                viewBox="0 0 16 16"
              >
                <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
              </svg>
            )}
          </button>
        </div>
      </div>

      {taskDetails?.taskType !== "subtask" && (
        <>
          <span className="fs-5 text-dark d-flex align-items-center gap-2">
            SUBTASKS
            <span className="badge badge-sm badge-light">
              {allSubTask?.length}
            </span>
            <button
              onClick={() => {
                setisSubTaskAdd(true);
              }}
              className="btn btn-xs btn-outline-light"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-plus-lg"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                />
              </svg>
            </button>
          </span>

          <div className="d-flex flex-column gap-3 my-1">
            {isSubTaskAdd && (
              <div className="d-flex align-items-center gap-2">
                <input
                  className="form-control border-0 border-bottom"
                  type="text"
                  placeholder="Enter Subtask Title"
                  value={subTaskName}
                  onChange={(e) => {
                    setsubTaskName(e.target.value);
                  }}
                />
                <button
                  disabled={subTaskLoader || subTaskName === ""}
                  className="btn btn-primary btn-sm"
                  onClick={handleCreateSubtask}
                >
                  Add
                </button>
                <button
                  disabled={subTaskLoader}
                  className="btn btn-outline-light btn-sm"
                  onClick={() => {
                    setisSubTaskAdd(false);
                  }}
                >
                  <i className="ri-close-line"></i>
                </button>
              </div>
            )}

            <div
              className=" d-flex flex-column gap-2 simple-scrollbar"
              style={{ overflowY: "scroll", maxHeight: "27vh" }}
            >
              {subTaskLoader ? (
                <p className="text-center">Loading Subtask....</p>
              ) : (
                <>
                  {allSubTask?.map((sub, index) => (
                    <div className=" py-1 px-2 rounded-4 border shadow-sm d-flex align-items-center justify-content-between me-1">
                      <p>
                        {index + 1}.&nbsp; {sub.name}
                      </p>
                      <div className="d-flex align-items-center gap-2">
                        <span
                          className="btn btn-xs btn-flush-light flush-soft-hover"
                          onClick={() => {
                            seterrorMessage("");
                            seteditedTaskOrSubTask(sub);
                            setisEdited(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pencil"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                          </svg>
                        </span>
                        <span
                          className="btn btn-xs btn-flush-danger flush-soft-hover"
                          onClick={() => {
                            seterrorMessage("");
                            handleDeleteSubTask(sub?._id);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                          </svg>
                        </span>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DetailsLeft;
