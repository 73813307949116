const PRIVATEROUTES = {
  ADD_ROLE: "Add Role",
  ROLE_LIST: "Role List",
  MANAGE_PEOPLE: "Manage People",
  SPACE: "Departments",
  COMMUNICATION: "Communication",
  STAFFMANAGEMENT: "Staff Management",
  LEAVE: "Leave",
  GRIVEANCE: "Griveance",
  ONBOARDING: "Onboarding",
  ATTENDANCE: "Attendance",
  SALARY: "Salary",
  MY_GRIEVANCE: "My Grievance",
  ALL_GRIEVANCE: "All Grievance",
  GRIVEANCE_HOME: "Overview",
  LEAVE_TYPE: "Leave Type",
  LEAVE_REQUEST: "Leave Requests",
  LEAVE_HISTORY: "Leave History",
  MEETING: "Meeting",
  NOTICE: "Notice",
  POLL: "Poll",
  HOLIDAY: "Holiday",
  LOCATION: "Location",
  SHIFT: "Shift",
  ITEMS: "Items",
  CUSTOMERS: "Customers",
  INVOICES: "Invoices",
  QUOTES: "Quotes",
  CREDIT_NOTES: "Credit Notes",
  CHART_OF_ACCOUNTS: "Chart Of Accounts",
  RAW_MATERIALS: "Raw Materials",
  MANAGE_STOCK: "Manage Stock",
  CUSTOMER: "Customer",
  MENU_ITEMS: "Menu Items",
  ORDER: "Place Order",
  ORDERAPPROVALS: "Order Approvals",
  TABLES: "Manage Table",
  ORDER_HISTORY: "Order History",
  ORDER_BILL: "Bills",
  INVENTORY_SETTINGS: "Settings",
  INVENTORY_ANALYTICS: "Home",
  VENDORS: "Vendors",
  BILLS: "Bills",
  EXPENSES: "Expenses",
  CASHINHAND: "Cash In Hand",
  LEADS: "Leads",
  DEALS: "Deals",
  CONTACTS: "Contacts",
  CRM_MEETING: "Meetings",
  CRM_TASK: "Tasks",
  CRM_CALLS: "Calls",
  PARTICIPANT_GROUP: "Participants Group",
  STATUS_GROUP: "Status Group",
  ACTIVITY_GROUP: "Activity Group",
  CRM_ANALYTICS: "Business Analytics",
  CRM_SETTINGS: "Settings",
  Employee_Analytics: "Employee Analytics",
  Task_Overview: "Task Overview",
  Performance: "Performance",
};

export default PRIVATEROUTES;
