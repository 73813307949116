import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

const GaugeChartDeal = ({ value, generatedLeadsTarget }) => {
  const total = value !== 0 ? (value / generatedLeadsTarget) * 100 : 0;
  const data = {
    labels: ["Filled", "Remaining"],
    datasets: [
      {
        data: [total, 100 - total],
        backgroundColor: ["#36A2EB", "#E7E9ED"],
        hoverBackgroundColor: ["#36A2EB", "#E7E9ED"],
      },
    ],
  };

  const options = {
    rotation: -90,
    circumference: 180,
    cutout: "60%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div style={{ position: "relative", width: "350px", height: "305px" }}>
      <Doughnut data={data} options={options} />
      <div
        style={{
          position: "absolute",
          top: "20%",
          left: "43%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          fontSize: "24px",
        }}
      >
        { parseFloat(total)?.toFixed(2)}%
      </div>
      <div
        style={{
          position: "absolute",
          bottom: `${total >= 50 ? "20%" : "15%"}`,
          left: `${total >= 50 ? "45%" : "40%"}`,
          transform: `translateX(-50%) rotate(${total * 1.8 - 90}deg)`,
        }}
      >
        <svg
          width="23"
          height="78"
          viewBox="0 0 23 108"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.0994 0.238045L20.7441 80.4145L3.25147 80.8276L10.0994 0.238045Z"
            fill="black"
          />
          <ellipse
            cx="11.5156"
            cy="82.6106"
            rx="9.34495"
            ry="9.71875"
            transform="rotate(90 11.5156 82.6106)"
            fill="#008492"
          />
        </svg>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "8%",
          right: "-8%",
          transform: "translate(-50%, -50%)",
        }}
      >
        Deal Target : {generatedLeadsTarget}
      </div>
    </div>
  );
};

export default GaugeChartDeal;
