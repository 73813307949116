import React from "react";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";

const LineChart = ({totalComplaint}) => {
  const lineChartOptions = {
    responsive: true, 
    maintainAspectRatio: false,
    // plugins: {
    //   title: {
    //     display: true,
    //     text: "Chart.js Stacked Line/Bar Chart",
    //   },
    // },
    scales: {
      y: {
        stacked: true,
        beginAtZero: true,
      },
    },
  };
  const lineChartData = {
    labels: totalComplaint?.map((data) => data.month),
    datasets: [
      {
        label: "Total Grievances",
        data: totalComplaint?.map((data) => data.total),
        fill: false,
        borderColor: "#008492",
      },
    ],
  };
  return (
    <>
      <div className="d-flex justify-content-center h-300p">
        <Chart type="line" data={lineChartData} options={lineChartOptions} />
      </div>
    </>
  );
};

export default LineChart;
