import React from "react";

function VerifyImageAndLocation({ imageAndLocation, setimageAndLocation }) {
  return (
    <>
      <div
        id="attendance_imglocation_modal"
        className="modal fade add-new-task"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          style={{ maxWidth: "860px" }}
          role="document"
        >
          <div className="modal-content overflow-auto">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setimageAndLocation([]);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h5>Attendance Location & Image</h5>
              <div className="border-bottom mb-2"></div>
              <form>
                <div className="row">
                  <div className="col-md-6 px-2">
                    <div className="d-flex flex-column align-items-center gap-1">
                      <p className="mb-0 fw-bold text-dark text-uppercase">
                        Last Login Location & Image
                      </p>

                      {imageAndLocation?.image ? (
                        <img
                          className="p-2 w-100 h-420p"
                          style={{
                            backgroundRepeat: "no-repeat",
                            objectFit: "contain",
                            backgroundPosition: "center",
                          }}
                          src={imageAndLocation?.image}
                          alt="Last Login Image"
                        />
                      ) : (
                        <span className="w-100 h-420p p-10">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            class="bi bi-person-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                          </svg>
                        </span>
                      )}

                      {imageAndLocation?.gpsLocation ? (
                        <a
                          href={imageAndLocation?.gpsLocation}
                          className="pointer text-primary d-flex align-items-center gap-1"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-geo-alt"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                          </svg>
                          <p className="mb-0">Last LogIn Location</p>
                        </a>
                      ) : (
                        <span>No Loacation Found</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 px-2">
                    <div className="d-flex flex-column align-items-center gap-1">
                      <p className="mb-0 fw-bold text-dark text-uppercase">
                        Last Logout Location & Image
                      </p>

                      {imageAndLocation?.logoutImage ? (
                        <img
                          className="p-2 w-100 h-420p"
                          style={{
                            backgroundRepeat: "no-repeat",
                            objectFit: "contain",
                            backgroundPosition: "center",
                          }}
                          src={imageAndLocation?.logoutImage}
                          alt="Last Logout Image"
                        />
                      ) : (
                        <span className="w-100 h-420p p-10">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            class="bi bi-person-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                          </svg>
                        </span>
                      )}

                      {imageAndLocation?.logoutGpsLocation ? (
                        <a
                          href={imageAndLocation?.logoutGpsLocation}
                          className="text-decoration-underline pointer"
                          style={{ color: "#008492" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-geo-alt me-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                          </svg>
                          Last Logout Location
                        </a>
                      ) : (
                        <span>No Loacation Found</span>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VerifyImageAndLocation;
