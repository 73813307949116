import React, { useState } from "react";
import ApiLoader from "../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../components/Error/ErrorMessage";
import ApiCall from "../../api/ApiCall";
import { listEndpoint } from "../../api/Endpoints";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../global-functions/globalFunctions";
import PermissionModal from "../../components/PermisionModal/PermissionPage";
import { toast } from "react-toastify";
import constants, { MAX_LENGTH } from "../../components/Constant/Constants";

function AddList(props) {
  const { modalId, spaceId, user, handleGetFolderList, id, handleGetAllLists } =
    props;
  const [listName, setlistName] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [makePrivate, setmakePrivate] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [removedEmployee, setremovedEmployee] = useState([]);
  const [searchKey, setsearchKey] = useState("");
  const [closeModal, setcloseModal] = useState(false);

  const endApiCall = () => {
    setLoader(false);
    setlistName("");
    seterrorMessage("");
    setmakePrivate(false);
    setSelectedEmployees([]);
    setcloseModal(true);
    const closeModalDom = document.getElementById(`${modalId}`);
    if (closeModalDom) closeModalDom.click();
  };

  const handleAddEemployeeInFolder = async (id) => {
    startApiCall(seterrorMessage, setLoader);
    let tempForSelected = [];
    let tempForRemoved = [];
    if (makePrivate) {
      selectedEmployees?.map((employeeId) => {
        tempForSelected.push({
          employeeId,
          isRemoved: false,
        });
      });
      removedEmployee?.map((employeeId) => {
        tempForRemoved.push({
          employeeId,
          isRemoved: true,
        });
      });
    }
    const sendData = {
      employees: tempForSelected?.concat(tempForRemoved),
      listId: id,
      isPrivate: true,
      userId: user?._id,
    };
    const res = await ApiCall("post", listEndpoint.addRemoveEmployee, sendData);
    if (res?.success) {
      if (modalId === "add_folder_list") {
        handleGetAllLists();
      } else {
        handleGetFolderList();
      }
      endApiCall();
      toast.success("Add Sub Role successfully!");
    } else {
      setLoader(false);
      seterrorMessage(res.error);
    }
  };

  const handleAddList = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    const payload = {
      name: listName,
      spaceId,
      businessId: getCurrentWorkspaceId(),
      isFoldered: modalId === "add_folder_list" ? true : false,
      folderId: id,
      createdBy: user?._id,
    };
    const res = await ApiCall("post", listEndpoint.createList, payload);
    if (res?.success) {
      if (makePrivate) {
        handleAddEemployeeInFolder(res?.list?._id);
      } else {
        if (modalId === "add_folder_list") {
          handleGetAllLists();
        } else {
          handleGetFolderList();
        }
        endApiCall();
        toast.success("Add Sub Role successfully!");
      }
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  return (
    <div
      id={`${modalId}`}
      className="modal fade add-tasklist-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{
        pointerEvents: loader ? "none" : "",
      }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-md"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={endApiCall}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="mb-4">Add Sub Role</h5>
            <form onSubmit={handleAddList}>
              <div className="row gx-3">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      value={listName}
                      onChange={(e) => {
                        setlistName(e.target.value);
                      }}
                      maxLength={constants.MAX_LENGTH}
                    />
                  </div>
                </div>
                <PermissionModal
                  makePrivate={makePrivate}
                  setmakePrivate={setmakePrivate}
                  selectedEmployees={selectedEmployees}
                  setSelectedEmployees={setSelectedEmployees}
                  removedEmployee={removedEmployee}
                  setremovedEmployee={setremovedEmployee}
                  searchKey={searchKey}
                  setsearchKey={setsearchKey}
                  type={
                    modalId === "add_folder_list"
                      ? "addListIntoFolder"
                      : "folder"
                  }
                  closeModal={closeModal}
                  infoData={modalId === "add_folder_list" ? id : spaceId}
                  isAddFolder={true}
                  isAddListIntoFolder={
                    modalId === "add_folder_list" ? true : false
                  }
                  isAddList={true}
                />
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}

              <div className="modal-footer align-items-center">
                <button
                  disabled={loader}
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={endApiCall}
                >
                  Cancel
                </button>
                <button
                  disabled={loader}
                  type="submit"
                  className="btn btn-primary"
                >
                  {loader ? <ApiLoader /> : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddList;
