import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ApiCall from "../../api/ApiCall";
import { businessEndpoints } from "../../api/Endpoints";
import timeManagement from "../../assets/images/pending_workspace.svg";
import ErrorMessage from "../../components/Error/ErrorMessage";
import ApiLoader from "../../components/Loaders/ApiLoader/ApiLoader";
import {
  getAllMyBusinessesUsingFilter,
  getAllStatusBusinesses,
  startApiCall,
} from "../../global-functions/globalFunctions";
import { actionCreators } from "../../redux-setup";

const PendingWorkspaceModal = (props) => {
  const { myApproveWorkspaces, user, myPendingWorkspaces } = props;
  const navigate = useNavigate();
  const [name, setname] = useState("");
  const [loader, setLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const dispatch = useDispatch();

  const endApiCall = () => {
    setname("");
    setLoader(false);
    handleModalHide();
  };

  useEffect(() => {
    if (
      myApproveWorkspaces?.length === 0 &&
      myPendingWorkspaces?.length === 1
    ) {
      const modal = new window.bootstrap.Modal(
        document.getElementById("show_pending_modal")
      );
      modal._element.addEventListener("click", (event) => {
        if (event.target === modal._element) {
          modal.hide();
        }
      });
      modal.show();
    }
  }, [myPendingWorkspaces]);

  const handleModalHide = () => {
    const modal = document.getElementById("show_pending_modal");
    modal.click();
  };
  const navigateAndCloseModal = () => {
    handleModalHide();
    navigate("/workspace");
  };

  const handleAddWorkSpace = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    const payload = {
      name: name,
      country: "India",
      city: "Kolkata",
      state: "West Bengal",
      createdBy: user?._id,
      description: "",
      image: "",
    };
    const res = await ApiCall("post", businessEndpoints.addBusiness, payload);
    if (res?.success) {
      handleBusiness();
      endApiCall();
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  const handleBusiness = async () => {
    const allWorkspaces = await getAllStatusBusinesses(user?._id);
    let allPendingWorkspaces = await getAllMyBusinessesUsingFilter(
      allWorkspaces?.business,
      "pending"
    );
    let allApprovedWorkspaces = await getAllMyBusinessesUsingFilter(
      allWorkspaces?.business,
      "approved"
    );
    dispatch(actionCreators.myPendingWorkspaces(allPendingWorkspaces));
    dispatch(actionCreators.myApproveWorkspaces(allApprovedWorkspaces));
  };

  return (
    <div
      id="show_pending_modal"
      className="modal fade show"
      tabIndex="-1"
      role="modal"
      aria-hidden="true"
    >
      <form onSubmit={handleAddWorkSpace}>
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
          style={{ maxWidth: "50%" }}
        >
          <div className="modal-content">
            <div className="modal-body p-6">
              <div className="d-flex align-items-center justify-content-center flex-column h-100">
                <p className="fs-3 fw-medium text-dark">
                  Your recent request is currently in Pending Status
                </p>
                <p
                  className="w-65 fs-7"
                  style={{ textAlign: "center", fontWeight: 300 }}
                >
                  You will be notified as soon as a decision is made. Thank you
                  for your patience.&nbsp;
                  <span
                    onClick={navigateAndCloseModal}
                    className="text-primary pointer underline"
                  >
                    check more details
                  </span>
                </p>
                <img className="w-50 p-4" src={timeManagement} alt="" />
                <div className="d-flex align-items-center justify-content-between flex-column p-1 gap-1 w-95">
                  <p
                    className="align-self-start text-dark"
                    style={{ fontWeight: 300, fontSize: "24px" }}
                  >
                    Add another company
                  </p>
                  <input
                    type="text"
                    required
                    className=" border-0 border-bottom w-100 pb-2"
                    placeholder="Enter name of your company or organization"
                    style={{ outline: 0 }}
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                  />
                  {errorMessage && <ErrorMessage errorMessage={errorMessage} />}

                  <div className="d-flex mt-4 gap-2 justify-content-end w-100">
                    <button
                      className="btn btn-light btn-rounded px-5"
                      type="button"
                      onClick={handleModalHide}
                    >
                      <span aria-hidden="true">Close</span>
                    </button>
                    <button
                      disabled={loader}
                      type="submit"
                      className="btn btn-primary btn-rounded px-5"
                    >
                      {loader ? <ApiLoader /> : "Add Workspace"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PendingWorkspaceModal;
