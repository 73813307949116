import React, { useState } from "react";
import {
  dateFormatter,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { Avatar } from "../../../components/Avatar/Avatar";
import { toast } from "react-toastify";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import { taskEndpoint } from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";

function StatusBody(props) {
  const {
    allStatus,
    seteditStatusModal,
    setidividualStatusData,
    handlTaskStatus,
    idividualStatusData,
    isModuleReadWriteAccessForSpace
  } = props;
  const [confirmDelete, setconfirmDelete] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setloader] = useState(false);

  const handleCloseConfirmModal = () => {
    setconfirmDelete(false);
    seterrorMessage("");
    setloader(false);
  };

  //*   DELETE STATUS    *//
  const handleDeleteStatus = async (id) => {
    startApiCall(null, setloader);
    const sendData = {
      taskStatusId: idividualStatusData?._id,
    };
    const res = await ApiCall("post", taskEndpoint.deleteStatus, sendData);
    if (res?.success) {
      setloader(false);
      toast.success("Status Deleted successfully!");
      handlTaskStatus();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const groupedstatus = allStatus?.reduce((groups, status) => {
    const statusType = status?.status_type;
    if (!groups[statusType]) {
      groups[statusType] = [];
    }
    groups[statusType].push(status);
    return groups;
  }, {});

  return (
    <div className="table-wrap">
      <div
        id="datable_1t_wrapper"
        className="dataTables_wrapper dt-bootstrap5 no-footer"
      >
        <div className="row">
          <div className="col-sm-12 col-md-6"></div>
          <div className="col-sm-12 col-md-6"></div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <table
              id="datable_1t"
              className="table table-bordered nowrap gt-todo-table dataTable no-footer"
              role="grid"
            >
              <thead>
                <tr role="row">
                  <th className="w-25" rowspan="2" tabindex="0">
                    <div className="d-flex align-items-center">
                      <span>Status</span>
                    </div>
                  </th>
                  <th
                    className="w-15 text-center"
                    tabIndex="0"
                    rowSpan="1"
                    colSpan="1"
                  >
                    Created By
                  </th>
                  <th
                    className="w-10 text-center"
                    tabIndex="0"
                    rowSpan="1"
                    colSpan="1"
                  >
                    Date Created
                  </th>
                  <th
                    className="w-10 text-center"
                    tabIndex="0"
                    rowSpan="1"
                    colSpan="1"
                  >
                    Color
                  </th>
             {  isModuleReadWriteAccessForSpace &&   <th
                    className="w-10 text-center"
                    tabIndex="0"
                    rowSpan="1"
                    colSpan="1"
                  >
                    Action
                  </th>}
                </tr>
              </thead>
              <tbody>
                {Object?.entries(groupedstatus)?.map(([statusType, status]) => (
                  <>
                    <tr className="group">
                      <td
                        className="row-sep"
                        colspan="5"
                        style={{
                          color:
                            statusType === "active"
                              ? "#0D6EFD"
                              : statusType === "done"
                              ? "#FFC400"
                              : statusType === "completed"
                              ? "#FF0101"
                              : "#32CD32",
                        }}
                      >
                        <span
                          class="badge badge-indicator badge-indicator-xl"
                          style={{
                            backgroundColor:
                              statusType === "active"
                                ? "#0D6EFD"
                                : statusType === "done"
                                ? "#FFC400"
                                : statusType === "completed"
                                ? "#FF0101"
                                : "#32CD32",
                          }}
                        ></span>
                        &nbsp;{statusType}
                      </td>
                    </tr>
                    {status?.map((st) => (
                      <tr className="odd" key={st?._id}>
                        <td className="text-center">
                          <div className="d-flex align-items-center gt-single-task">
                            <div>
                              <span className="todo-text text-capitalize">
                                &nbsp;{st?.statusName}{" "}
                                {st?.isDefault && "(Default)"}
                              </span>
                            </div>
                          </div>
                        </td>

                        <td className="text-center">
                          {st?.createdBy ? (
                            <span className="text-primary font-3">
                              &nbsp;
                              <CustomTooltip
                                text={st?.createdBy?.name}
                                placement="bottom"
                              >
                                <Avatar
                                  name={st?.createdBy?.name}
                                  count={1}
                                  image={st?.createdBy?.image}
                                  size="xss"
                                />
                              </CustomTooltip>
                              &nbsp;{st?.createdBy?.name}&nbsp;
                            </span>
                          ) : (
                            "  Opsshift Team"
                          )}
                        </td>
                        <td className="text-center">
                          <span className="text">
                            {" "}
                            {dateFormatter(st?.createdAt)}
                          </span>
                        </td>
                        <td className="text-center">
                          {" "}
                          <span
                            class="badge badge-indicator badge-indicator-xl"
                            style={{ backgroundColor: st?.color }}
                          ></span>
                          &nbsp;{st?.color}
                        </td>
                   { isModuleReadWriteAccessForSpace &&    <td className="text-center">
                          {st?.isDefault ? (
                            "-"
                          ) : (
                            <div
                              class="col-sm-12 btn-group"
                              role="group"
                              aria-label="Basic outlined example"
                            >
                              <button
                                type="button"
                                class="btn btn-xs btn-outline-primary"
                                onClick={() => {
                                  seteditStatusModal(true);
                                  setidividualStatusData(st);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  class="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                  />
                                </svg>
                              </button>
                              <button
                                type="button"
                                class="btn btn-xs btn-outline-danger"
                                onClick={() => {
                                  setidividualStatusData(st);
                                  setconfirmDelete(true);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  class="bi bi-trash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                </svg>
                              </button>
                            </div>
                          )}
                        </td>}
                      </tr>
                    ))}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-5"></div>
          <div className="col-sm-12 col-md-7"></div>
        </div>
      </div>
      <ConfirmModal
        isOpen={confirmDelete}
        onClose={handleCloseConfirmModal}
        text={"Are You Sure You Want To Delete This Status"}
        loader={loader}
        errorMessage={errorMessage}
        handleConfirm={handleDeleteStatus}
      />
    </div>
  );
}

export default StatusBody;
