import React from "react";

function WorkspaceDrawer(props) {
  const { isOpenSideDrawer, setfilterStatus, filterStatus, workspaceCounts } =
    props;

  return (
    <>
      {isOpenSideDrawer && (
        <nav className="todoapp-sidebar">
          <div data-simplebar className="nicescroll-bar simple-scrollbar">
            <div className="menu-content-wrap">
              <button
                className="btn btn-primary btn-rounded btn-block mb-4"
                data-bs-toggle="modal"
                data-bs-target="#add_new_business"
                style={{ minWidth: "147px" }}
              >
                Add Company
              </button>
              <div className="menu-group">
                <ul className="nav nav-light navbar-nav flex-column">
                  <li className="nav-item active">
                    <span className="nav-link">
                      <span className="nav-icon-wrap">
                        <span className="feather-icon">
                          <i className="ri-layout-line"></i>
                        </span>
                      </span>
                      <span className="nav-link-text">Active</span>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="separator separator-light"></div>
              <div className="title-sm text-primary">Status</div>
              <div className="menu-group">
                <ul className="nav nav-light navbar-nav flex-column">
                  <li className="nav-item">
                    <span className="nav-link link-with-badge">
                      <span className="badge badge-primary badge-indicator badge-indicator-lg me-2"></span>

                      <span className="nav-link-text me-1">All</span>
                      <span className="badge badge-pill badge-sm badge-soft-primary">
                        {workspaceCounts?.all}
                      </span>
                      <input
                        type="checkbox"
                        value=""
                        className="form-check-input  ms-auto me-2 pointer pointer"
                        onChange={(e) => {
                          setfilterStatus(e.target.value);
                        }}
                        checked={filterStatus === ""}
                      />
                    </span>
                  </li>

                  <li className="nav-item">
                    <span className="nav-link link-with-badge">
                      <span className="badge badge-green badge-indicator badge-indicator-lg me-2"></span>

                      <span className="nav-link-text me-1">Active</span>
                      <span className="badge badge-pill badge-sm badge-soft-success">
                        {workspaceCounts?.approved}
                      </span>
                      <input
                        type="checkbox"
                        value="approved"
                        className="form-check-input  ms-auto me-2 pointer"
                        onChange={(e) => {
                          setfilterStatus(e.target.value);
                        }}
                        checked={filterStatus === "approved"}
                      />
                    </span>
                  </li>
                  <li className="nav-item">
                    <span className="nav-link link-with-badge">
                      <span className="badge badge-yellow badge-indicator badge-indicator-lg me-2"></span>

                      <span className="nav-link-text me-1">Pending</span>
                      <span className="badge badge-pill badge-sm badge-soft-warning">
                        {workspaceCounts?.pending}
                      </span>
                      <input
                        type="checkbox"
                        value="pending"
                        className="form-check-input  ms-auto me-2 pointer"
                        onChange={(e) => {
                          setfilterStatus(e.target.value);
                        }}
                        checked={filterStatus === "pending"}
                      />
                    </span>
                  </li>
                  <li className="nav-item">
                    <span className="nav-link link-with-badge">
                      <span className="badge badge-red badge-indicator badge-indicator-lg me-2"></span>

                      <span className="nav-link-text me-1">Reject</span>
                      <span className="badge badge-pill badge-sm badge-soft-danger">
                        {workspaceCounts?.rejected}
                      </span>
                      <input
                        type="checkbox"
                        value="rejected"
                        className="form-check-input  ms-auto me-2 pointer"
                        onChange={(e) => {
                          setfilterStatus(e.target.value);
                        }}
                        checked={filterStatus === "rejected"}
                      />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="todoapp-fixednav">
            <div className="hk-toolbar">
              <ul className="nav nav-light">
                <li className="nav-item nav-link">
                  <span
                    className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    data-bs-original-title="Settings"
                  >
                    <span className="icon">
                      <span className="feather-icon">
                        <i data-feather="settings"></i>
                      </span>
                    </span>
                  </span>
                </li>
                <li className="nav-item nav-link">
                  <span
                    className="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    data-bs-original-title="Archive"
                  >
                    <span className="icon">
                      <span className="feather-icon">
                        <i data-feather="archive"></i>
                      </span>
                    </span>
                  </span>
                </li>
                <li className="nav-item nav-link">
                  <span
                    className="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    data-bs-original-title="Help"
                  >
                    <span className="icon">
                      <span className="feather-icon">
                        <i data-feather="book"></i>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}
    </>
  );
}

export default WorkspaceDrawer;
