import React, { useEffect, useState } from "react";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import NoData from "../../../../components/NoData/NoData";
import FooterPagination from "../../../../components/Pagination/FooterPagination";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";
import SkeletonConstant from "../../../../components/Constant/SkeletonConstant";
// import OnboardingSkeleton from "./OnboardingSkeleton";
import ApiCall from "../../../../api/ApiCall";
import { ShiftRelatedEndPoints } from "../../../../api/Endpoints";
import StaffAllShiftTable from "./StaffAllShiftTable";
import { useNavigate } from "react-router-dom";
import {
  navigateToBusinessShift,
  navigateToShiftDetails,
} from "../../../../Navigation/Navigation";
import AllShiftsSkeleton from "./AllShiftsSkeleton";

const StaffAllShifts = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { allModuleAccess } = useSelector((state) => state);
  const [search, setSearch] = useState("");
  const [loader, setloader] = useState(true);
  const [approvedStaff, setapprovedStaff] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setcount] = useState(0);
  const [pageLimit, setpageLimit] = useState(10);

  const handleGetStaff = async () => {
    startApiCall(null, setloader);
    const sendData = {
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: search,
        isDeleted: false,
      },
      page: page,
      limit: pageLimit,
    };

    const res = await ApiCall("post", ShiftRelatedEndPoints.getStaff, sendData);
    if (res?.success) {
      setcount(res?.staff?.totalCounts);
      setapprovedStaff(res?.staff?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  const isModuleReadWriteAccessShift = allModuleAccess?.[PRIVATEROUTES.SHIFT];

  useEffect(() => {
    if (!isModuleReadWriteAccessShift && approvedStaff?.length > 0) {
      const filteredApprovedStaff = approvedStaff?.filter(
        (item) => item?.employeeId?._id === user?.employeeId
      );
      navigate(
        navigateToShiftDetails({
          id: filteredApprovedStaff?.[0]?._id,
        }),
        {
          state: {
            name: filteredApprovedStaff?.[0]?.employeeId?.name,
            image: filteredApprovedStaff?.[0]?.employeeId?.image,
          },
        }
      );
    }
  }, [isModuleReadWriteAccessShift, approvedStaff]);

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetStaff();
      closeAllModals();
    }
  }, [search, page, pageLimit]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <header className="todo-header">
            <div className="d-flex align-items-center">
              <a className="todoapp-title  link-dark">
                <h1>Staff Shifts</h1>
              </a>
            </div>

            {!loader && approvedStaff?.length > 0 && (
              <div className="d-flex align-items-center">
                <span className="d-md-inline">Total Staff: </span>
                <span className="text-dark fs-5 fw-medium ps-2">{count}</span>
              </div>
            )}
            {loader && <SkeletonConstant width="w-140p" height="h-35p" />}
            {!loader && (
              <div className="todo-options-wrap">
                <div className="me-3">
                  <button
                    className="btn btn-sm btn-outline-secondary flex-shrink-0 d-lg-inline-block"
                    onClick={() => {
                      navigate(navigateToBusinessShift());
                    }}
                  >
                    Company Shift
                  </button>
                </div>
                <span
                  className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="dsdsd"
                  data-bs-original-title="Refresh"
                  onClick={handleGetStaff}
                >
                  <span className="btn-icon-wrap">
                    <span className="feather-icon">
                      <i
                        className="ri-refresh-line"
                        title="Refresh Shift list"
                      ></i>
                    </span>
                  </span>
                </span>
                <div className="v-separator d-lg-inline-block d-none"></div>
                <form className="d-sm-block d-none" role="search">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by name"
                    value={search}
                    onChange={(e) => {
                      setPage(1);
                      setSearch(e.target.value);
                    }}
                  />
                </form>
              </div>
            )}
          </header>

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="todo-list-view">
                {!loader && approvedStaff?.length > 0 && (
                  <StaffAllShiftTable
                    approvedStaff={approvedStaff}
                    page={page}
                    pageLimit={pageLimit}
                  />
                )}
                {loader && <AllShiftsSkeleton />}
                {!loader && approvedStaff?.length === 0 && <NoData />}
              </div>
              {!loader && approvedStaff?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={count}
                  count={count}
                  dataLength={approvedStaff?.length}
                  align={"end"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffAllShifts;
