import React from "react";
import { Avatar } from "../../../components/Avatar/Avatar";
import { dateAndTimeFormatter } from "../../../global-functions/globalFunctions";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";

const PollCard = (props) => {
  const {
    poll,
    index,
    pageLimit,
    page,
    setselectedPoll,
    user,
    setisConfirmClose,
    isModuleReadWriteAccessPoll,
  } = props;

  const isAlreadyVoted = () => {
    let isVoted = false;
    poll?.votedBy?.some((emp) => {
      if (emp?._id === user?._id) {
        return (isVoted = true);
      }
    });
    return isVoted;
  };

  const isPollExpired = () => {
    return new Date().toISOString() >= poll?.expiresAt ? true : false;
  };

  return (
    <div
      className="card p-3 d-flex flex-column gap-2"
      style={{
        border: isPollExpired() || poll?.isClosed ? "0.981px solid red" : "",
      }}
    >
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-start gap-2">
          <div className="avatar avatar-sm avatar-rounded position-relative mb-2">
            <Avatar
              name={poll?.createdBy?.name}
              count={1}
              image={poll?.createdBy?.image}
              size="sm"
              color="primary"
            />
          </div>
          <div className="d-flex flex-column">
            <p className="text-dark mt-2">{poll?.createdBy?.name}</p>
          </div>
        </div>
        <p className="fs-7">
          Published on : {dateAndTimeFormatter(poll?.createdAt)}
        </p>
      </div>
      <p className="text-dark fw-semibold">{poll?.title}</p>
      {poll?.options?.map((opt) => {
        return (
          <div className="d-flex align-items-center gap-2">
            <p className="text-dark">{opt?.name}</p>
            <div class="progress w-40" style={{ backgroundColor: "#c8c8c8" }}>
              <div
                class="progress-bar progress-bar-md bg-success"
                role="progressbar"
                aria-valuenow="90"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${opt?.percentage}%` }}
              ></div>
            </div>
            <p className="text-dark">{opt?.percentage}%</p>
          </div>
        );
      })}

      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-3">
          {poll?.votedBy?.length > 0 && (
            <span className="avatar-group avatar-group-sm avatar-group-overlapped">
              {poll?.votedBy?.map((emp) => {
                return (
                  <div className="avatar avatar-rounded pointer">
                    <CustomTooltip text={emp?.name} placement="bottom">
                      <Avatar
                        name={emp?.name}
                        count={1}
                        image={emp?.image}
                        size="xss"
                        color="primary"
                      />
                    </CustomTooltip>
                  </div>
                );
              })}
            </span>
          )}
          <p>
            Total Votes :{" "}
            <span className="text-primary">{poll?.votedBy?.length}</span>{" "}
          </p>
          <p className="text-end font-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-geo-alt"
              viewBox="0 0 16 16"
            >
              <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
              <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            </svg>
            <span className="text-dark ms-1">
              {poll?.moduleType === "Business" ? "Company" : poll?.moduleType}{" "}
              {poll?.moduleId && `(${poll?.moduleId?.name})`}
            </span>
          </p>
          {!poll?.isClosed && (
            <p>
              {isPollExpired() ? (
                <span className="text-danger">Poll is Expired</span>
              ) : (
                <p>Expired Date : {dateAndTimeFormatter(poll?.expiresAt)}</p>
              )}
            </p>
          )}
          {poll?.isClosed && (
            <span className="text-danger">Poll is Closed</span>
          )}
        </div>
        {isModuleReadWriteAccessPoll && (
          <>
            {!isPollExpired() && !poll?.isClosed && (
              <div>
                {isAlreadyVoted() ? (
                  <span
                    style={{ cursor: "no-drop" }}
                    className="btn btn-secondary btn-sm"
                  >
                    Voted
                  </span>
                ) : (
                  <span
                    data-bs-toggle="modal"
                    data-bs-target="#add_new_vote"
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      setselectedPoll(poll);
                    }}
                  >
                    Vote
                  </span>
                )}
                {poll?.createdBy?._id === user?._id && (
                  <span
                    onClick={() => {
                      setselectedPoll(poll);
                      setisConfirmClose(true);
                    }}
                    style={{ backgroundColor: "#fa4848", color: "white" }}
                    className="btn btn-sm ms-1"
                  >
                    Close Poll
                  </span>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PollCard;
