import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ApiCall from "../../api/ApiCall";
import { businessEndpoints } from "../../api/Endpoints";
import GetStarted from "../../assets/images/get_started.png";
import ErrorMessage from "../../components/Error/ErrorMessage";
import ApiLoader from "../../components/Loaders/ApiLoader/ApiLoader";
import {
  getAllMyBusinessesUsingFilter,
  getAllStatusBusinesses,
  startApiCall,
} from "../../global-functions/globalFunctions";
import { actionCreators } from "../../redux-setup";

const StartAddWorkspace = (props) => {
  const { myApproveWorkspaces, user, myPendingWorkspaces } = props;
  const [name, setname] = useState("");
  const [loader, setLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const dispatch = useDispatch();

  const endApiCall = () => {
    setname("");
    setLoader(false);
    handleModalHide();
  };
  useEffect(() => {
    if (
      myApproveWorkspaces?.length === 0 &&
      myPendingWorkspaces?.length === 0
    ) {
      const modal = new window.bootstrap.Modal(
        document.getElementById("get_started_modal")
      );
      modal._element.addEventListener("click", (event) => {
        if (event.target === modal._element) {
          modal.hide();
        }
      });
      modal.show();
    }
  }, [myApproveWorkspaces]);

  const handleModalHide = () => {
    const modal = document.getElementById("get_started_modal");
    modal.click();
  };

  const handleAddWorkSpace = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    const payload = {
      name: name,
      country: "India",
      city: "Kolkata",
      state: "West Bengal",
      createdBy: user?._id,
      description: "",
      image: "",
    };
    const res = await ApiCall("post", businessEndpoints.addBusiness, payload);
    if (res?.success) {
      handleBusiness();
      endApiCall();
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  const handleBusiness = async () => {
    const allWorkspaces = await getAllStatusBusinesses(user?._id);
    let allPendingWorkspaces = await getAllMyBusinessesUsingFilter(
      allWorkspaces?.business,
      "pending"
    );
    let allApprovedWorkspaces = await getAllMyBusinessesUsingFilter(
      allWorkspaces?.business,
      "approved"
    );
    dispatch(actionCreators.myPendingWorkspaces(allPendingWorkspaces));
    dispatch(actionCreators.myApproveWorkspaces(allApprovedWorkspaces));
  };

  return (
    <div
      id="get_started_modal"
      className="modal fade show"
      tabIndex="-1"
      role="modal"
      aria-hidden="true"
    >
      <form onSubmit={handleAddWorkSpace}>
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
          style={{ maxWidth: "48%" }}
        >
          <div className="modal-content">
            <div className="modal-body p-6">
              <div className="d-flex justify-content-end">
                <button type="button" className="btn" onClick={handleModalHide}>
                  <span className="text-primary  fw-medium">SKIP</span>
                </button>
              </div>

              <div className="d-flex align-items-center justify-content-center flex-column h-100">
                <p className="fs-3 fw-medium text-dark">
                  Add a company to get started!
                </p>
                <p
                  className="w-65 fs-7"
                  style={{ textAlign: "center", fontWeight: 300 }}
                >
                  Excited to manage your work in a hassle free way? Bring your
                  company onboard and get productive.
                </p>
                <img className="w-60 p-4" src={GetStarted} alt="" />
                <div className="d-flex align-items-center justify-content-between flex-column p-1 gap-1 w-95">
                  <p
                    className="align-self-start text-dark"
                    style={{ fontWeight: 300, fontSize: "24px" }}
                  >
                    Name
                  </p>
                  <input
                    type="text"
                    required
                    className=" border-0 border-bottom w-100 pb-2"
                    placeholder="Enter name of your workspace, company or organization"
                    style={{ outline: 0 }}
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                  />
                  {errorMessage && <ErrorMessage errorMessage={errorMessage} />}

                  <button
                    disabled={loader}
                    type="submit"
                    className="btn btn-primary btn-rounded mt-4 px-7"
                  >
                    {loader ? <ApiLoader /> : "Add Workspace"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default StartAddWorkspace;
