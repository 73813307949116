import React, { useState } from "react";
import { useSelector } from "react-redux";
import ApiCall from "../../api/ApiCall";
import { spaceEndpoint } from "../../api/Endpoints";
import ErrorMessage from "../../components/Error/ErrorMessage";
import ApiLoader from "../../components/Loaders/ApiLoader/ApiLoader";
import PermissionModal from "../../components/PermisionModal/PermissionPage";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../global-functions/globalFunctions";
import { toast } from "react-toastify";
import constants from "../../components/Constant/Constants";

const spaceThemeColors = [
  "#40BC86",
  "#1ABC9C",
  "#27AE60",
  "#03A2FD",
  "#7B68EE",
  "#C45AD0",
  "#074354",
  "#42556A",
  "#181D21",
  "#2980B9",
  "#3498DB",
  "#0918EC",
  "#199EC7",
  "#01E455",
  "#EC6625",
];

function AddSpace({ handleGetSpace }) {
  const user = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [spaceName, setspaceName] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [makePrivate, setmakePrivate] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [removedEmployee, setremovedEmployee] = useState([]);
  const [searchKey, setsearchKey] = useState("");
  const [closeModal, setcloseModal] = useState(false);

  const handleReset = () => {
    setErrorMessage("");
    setspaceName("");
    setSelectedEmployees([]);
    setmakePrivate(false);
    setLoader(false);
    setcloseModal(true);
    const closeModalDom = document.getElementById("add_space");
    if (closeModalDom) closeModalDom.click();
  };

  const handleAddEmployeeInSpace = async (id) => {
    startApiCall(setErrorMessage, setLoader);
    let tempForSelected = [];
    let tempForRemoved = [];
    if (makePrivate) {
      selectedEmployees?.map((employeeId) => {
        tempForSelected.push({
          employeeId,
          isRemoved: false,
        });
      });
      removedEmployee?.map((employeeId) => {
        tempForRemoved.push({
          employeeId,
          isRemoved: true,
        });
      });
    }
    const sendData = {
      employees: tempForSelected?.concat(tempForRemoved),
      spaceId: id,
      isPrivate: true,
      userId: user?._id,
    };
    const res = await ApiCall(
      "post",
      spaceEndpoint.addRemoveEmployeesInSpcae,
      sendData
    );
    if (res?.success) {
      handleReset();
      handleGetSpace();
      toast.success("Create Department successfully!");
    } else {
      setLoader(false);
      setErrorMessage(res.error);
    }
  };

  const handleCreateSpace = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setLoader);
      const sendData = {
        name: spaceName,
        businessId: getCurrentWorkspaceId(),
        createdBy: user?._id,
        themeColor: selectedColor,
      };
      const res = await ApiCall("post", spaceEndpoint.createSpace, sendData);
      if (res?.success) {
        if (makePrivate) {
          handleAddEmployeeInSpace(res?.space?._id);
        } else {
          handleReset();
          handleGetSpace();
          toast.success("Create Department successfully!");
        }
      } else {
        setLoader(false);
        setErrorMessage(res.error);
      }
    
  };
  const handleColorClick = (clr) => {
    setSelectedColor(clr);
  };

  return (
    <div
      id="add_space"
      className="modal fade add-tasklist-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{
        pointerEvents: loader ? "none" : "",
      }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-md"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                handleReset();
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="mb-3">Customize your Department</h5>
            <div className="title title-sm title-wth-divider text-primary text-uppercase mb-2">
              <span>Basic Info</span>
            </div>

            <form onSubmit={handleCreateSpace}>
              <div className="row gx-3 mt-1 p-1">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Department Name</label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      placeholder="Enter department name"
                      value={spaceName}
                      onChange={(e) => {
                        setspaceName(e.target.value);
                      }}
                      maxLength={constants.SPACE_MAX_LENGTH}
                    />
                  </div>
                </div>
                <PermissionModal
                  makePrivate={makePrivate}
                  setmakePrivate={setmakePrivate}
                  selectedEmployees={selectedEmployees}
                  setSelectedEmployees={setSelectedEmployees}
                  removedEmployee={removedEmployee}
                  setremovedEmployee={setremovedEmployee}
                  searchKey={searchKey}
                  setsearchKey={setsearchKey}
                  setErrorMessage={setErrorMessage}
                  type="space"
                  closeModal={closeModal}
                />
              </div>
              <div className="title title-sm title-wth-divider text-primary text-uppercase ">
                <span
                  style={{
                    color: selectedColor !== "" ? selectedColor : "#000",
                  }}
                >
                  Choose theme
                </span>
              </div>
              <div className="theme-color-select mt-3 mb-2 p-3">
                <div className="row mt-2">
                  <div className="d-flex justify-content-center">
                    <ul className="color-grid">
                      <li
                        className="color-option"
                        onClick={() => handleColorClick("")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          fill="currentColor"
                          className="bi bi-slash-circle"
                          viewBox="0 0 16 16"
                          style={{
                            border: selectedColor === "" && "2px solid black",
                            borderRadius: selectedColor === "" && "50%",
                          }}
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708z" />
                        </svg>
                      </li>
                      {spaceThemeColors?.map((clr, index) => (
                        <li
                          key={index}
                          className="color-option"
                          onClick={() => handleColorClick(clr)}
                        >
                          {clr === selectedColor ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="23"
                              height="23"
                              fill={clr}
                              className="bi bi-check-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              viewBox="0 0 34 33"
                              fill="none"
                            >
                              <circle
                                cx="16.8544"
                                cy="16.5"
                                r="16.5"
                                fill={clr}
                              />
                              <text
                                x="50%"
                                y="50%"
                                dominantBaseline="middle"
                                textAnchor="middle"
                                fontSize="28"
                                fill="#979797"
                              ></text>
                            </svg>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="theme-color-select mt-3 mb-2 p-3">
                <span className="colortheme-tile">Pick an Avatar</span>
                <div className="row mt-3">
                  <ul className="icon-grid">
                    {svgIcon?.map((svg, index) => (
                      <li
                        key={index}
                        className={`icon-option ${
                          selectedIcon === svg?.icon ? "selected-icon" : ""
                        }`}
                        onClick={() => {
                          setSelectedIcon(svg?.icon);
                        }}
                      >
                        {svg?.icon}
                      </li>
                    ))}
                  </ul>
                </div>
              </div> */}
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Discard
                </button>
                <button
                  disabled={loader}
                  type="submit"
                  className="btn btn-primary btn-add-tasklist"
                >
                  {loader ? <ApiLoader /> : "Create Department"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSpace;
