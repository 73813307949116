import React, { useState } from "react";
import { Avatar } from "../../../../components/Avatar/Avatar";
import ConfirmModal from "../../../../components/ConfirmationModal/ConfirmModal";
import {
  dateAndTimeFormatter,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { noticeRelatedEndPoints } from "../../../../api/Endpoints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { navigateToNotice } from "../../../../Navigation/Navigation";

const NoticeDetailsCard = ({
  noticeDetails,
  setEditNoticeData,
  editNoticeData,
  id,
  handleGetNoticeDetails,
  user,
  isModuleReadWriteAccessNotice,
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    setIsOpen(false);
    setErrorMessage("");
    setloader(false);
  };

  const handleDeleteNotice = async () => {
    startApiCall(setErrorMessage, setloader);
    const res = await ApiCall("delete", noticeRelatedEndPoints.deleteNotice, {
      params: {
        noticeId: id,
        actionBy: user?._id,
      },
    });
    if (res?.success) {
      handleClose();
      toast.success("Notice Deleted Successfully!");
      navigate(navigateToNotice());
    } else {
      setErrorMessage(res.error);
      setloader(false);
    }
  };

  const handleChangeStatus = async () => {
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      noticeId: id,
      publishStatus:
        noticeDetails?.publishStatus === "unpublished"
          ? "published"
          : "unpublished",
      actionBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      noticeRelatedEndPoints.changeNoticeStatus,
      sendData
    );
    if (res?.success) {
      toast.success("Change status successfully!");
      handleGetNoticeDetails();
      setloader(false);
    } else {
      setErrorMessage(res.error);
      setloader(false);
    }
  };

  return (
    <>
      <div className="w-100 h-100 p-2">
        <div className="d-flex flex-column gap-3 border rounded-4 shadow p-3">
          <div className="d-flex justify-content-between gap-4">
            <div className="d-flex align-items-center gap-2">
              <span className="position-relative">
                <Avatar
                  name={noticeDetails?.raisedBy?.name}
                  count={1}
                  image={noticeDetails?.raisedBy?.image}
                  size="sm"
                  color="primary"
                />
                <span
                  className="badge badge-primary badge-indicator position-bottom-end-overflow-1 element-to-animate"
                  style={{ backgroundColor: "#16db8c" }}
                ></span>
              </span>
              <div>
                <p className="text-dark">{noticeDetails?.raisedBy?.name}</p>
              </div>
            </div>
            <div>
              <span
                className={`badge badge-md badge-soft-${
                  noticeDetails?.isDeleted === true
                    ? "red"
                    : noticeDetails?.publishStatus === "unpublished"
                    ? "yellow"
                    : "green"
                }`}
              >
                {noticeDetails?.isDeleted === true
                  ? "Deleted"
                  : noticeDetails?.publishStatus}
              </span>
            </div>
            <div className="d-flex gap-4">
              <span className="icon">
                <span className="position-relative">
                  <span className="feather-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye-slash-fill me-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                      <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                    </svg>
                  </span>
                  <span
                    className="badge badge-indicator text-secondary mt-1"
                    style={{
                      backgroundColor: "transparent",
                      verticalAlign: "text-top",
                    }}
                  >
                    {noticeDetails?.unseenBy}
                  </span>
                </span>
              </span>
              <span className="icon">
                <span className="position-relative">
                  <span className="feather-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye-fill me-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                    </svg>
                  </span>
                  <span
                    className="badge badge-indicator text-secondary mt-1"
                    style={{
                      backgroundColor: "transparent",
                      verticalAlign: "text-top",
                    }}
                  >
                    {noticeDetails?.seenBy}
                  </span>
                </span>
              </span>
              {isModuleReadWriteAccessNotice && (
                <>
                  {!noticeDetails?.isDeleted && (
                    <div className="d-flex selectable-dropdown">
                      <div className="dropdown selectable-dropdown">
                        <span
                          className="feather-icon pointer"
                          aria-expanded="false"
                          data-bs-toggle="dropdown"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-three-dots-vertical"
                            viewBox="0 0 16 16"
                          >
                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                          </svg>
                        </span>
                        <div role="menu" className="dropdown-menu">
                          <div
                            className="dropdown-item d-flex align-items-center"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_notice"
                            onClick={() => setEditNoticeData(noticeDetails)}
                          >
                            <span className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button">
                              <span className="btn-icon-wrap">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                  />
                                </svg>
                              </span>
                            </span>
                            <p>Edit Notice</p>
                          </div>
                          <div
                            className="dropdown-item d-flex align-items-center"
                            onClick={() => {
                              setIsOpen(true);
                            }}
                          >
                            <span
                              className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-danger"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="btn-icon-wrap">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-trash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                </svg>
                              </span>
                            </span>
                            <p>Delete Notice</p>
                          </div>
                          <div
                            className="dropdown-item d-flex align-items-center"
                            onClick={handleChangeStatus}
                          >
                            <span
                              className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-danger"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="btn-icon-wrap">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M6 4H21C21.5523 4 22 4.44772 22 5V12H20V6H6V9L1 5L6 1V4ZM18 20H3C2.44772 20 2 19.5523 2 19V12H4V18H18V15L23 19L18 23V20Z"></path>
                                </svg>
                              </span>
                            </span>
                            <p>Change Status</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="d-flex gap-3">
            <div className="d-flex flex-column gap-2 w-25 p-2">
              <div className="d-flex justify-content-center mt-4">
                <img
                  className="w-80 rounded h-250p"
                  src={
                    noticeDetails?.images?.length
                      ? noticeDetails?.images?.[0]
                      : "https://www.ncenet.com/wp-content/uploads/2020/04/No-image-found.jpg"
                  }
                  alt=""
                  style={{ objectFit: "contain" }}
                />
              </div>
            </div>
            <div className="d-flex flex-column gap-4 w-75 p-2">
              <p className="text-dark">Title</p>
              <div
                className="p-3 bg-light rounded-5 d-flex justify-content-between"
                style={{ marginTop: -15 }}
              >
                <p
                  className="fs-3 text-dark fw-semibold"
                  style={{ textTransform: "capitalize" }}
                >
                  {noticeDetails?.title}
                </p>
              </div>
              <p className="text-dark">Description</p>
              <div
                className="p-3 bg-light rounded-5 d-flex justify-content-between h-115p simplebar-hide-scrollbar"
                style={{ overflow: "auto", marginTop: -15 }}
              >
                <p
                  className="text-dark w-90"
                  style={{ textTransform: "capitalize" }}
                >
                  {noticeDetails?.description}
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column gap-3">
            <div className="d-flex justify-content-between align-items-end">
              <p className="fs-8">
                Published on : {dateAndTimeFormatter(noticeDetails?.createdAt)}
              </p>
              <p className="fs-7">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-geo-alt ms-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </svg>
                <span className="text-dark ms-1 text-capitalize">
                  {noticeDetails?.noticeType === "Business"
                    ? "Company"
                    : noticeDetails?.noticeType}{" "}
                  {noticeDetails?.moduleId &&
                    `(${noticeDetails?.moduleId?.name})`}
                </span>
              </p>
            </div>
            {noticeDetails?.actionBy && (
              <div className="d-flex justify-content-between align-items-end">
                <p>
                  Action By : &nbsp;
                  <Avatar
                    name={noticeDetails?.actionBy?.name}
                    count={1}
                    image={noticeDetails?.actionBy?.image}
                    size="xss"
                    color="primary"
                  />
                  &nbsp;&nbsp;
                  {noticeDetails?.actionBy?.name}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfirmModal
        isOpen={isOpen}
        onClose={handleClose}
        text="Are You Sure You Want To Delete This Notice"
        loader={loader}
        errorMessage={errorMessage}
        noticeId={editNoticeData}
        handleConfirm={handleDeleteNotice}
      />
    </>
  );
};

export default NoticeDetailsCard;
