import React, { useEffect, useState } from "react";
import ApiCall from "../../api/ApiCall";
import { businessEndpoints } from "../../api/Endpoints";
import ErrorMessage from "../../components/Error/ErrorMessage";
import ApiLoader from "../../components/Loaders/ApiLoader/ApiLoader";
import {
  getAllRolesForWorkspace,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../global-functions/globalFunctions";
import { INVITATION_URL } from "../../api/base";
import { toast } from "react-toastify";
import { navigateToRoleList } from "../../Navigation/Navigation";
import { Link } from "react-router-dom";

const InviteWorkspace = ({
  isOpenInviteWorkspaceModal,
  setisOpenInviteWorkspaceModal,
  user,
  senderEmail,
}) => {
  const [email, setEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [allRoles, setallRoles] = useState(null);
  const [loaderForRole, setLoaderForRole] = useState(false);

  useEffect(() => {
    if (senderEmail?.length > 0) {
      setEmail(senderEmail);
    }
  }, [isOpenInviteWorkspaceModal]);

  const endApiCall = () => {
    setEmail("");
    setSelectedRole("");
    setisOpenInviteWorkspaceModal(false);
    seterrorMessage("");
    setLoader(false);
  };
  const getAllRoles = async () => {
    startApiCall(null, setLoaderForRole);
    let roles = await getAllRolesForWorkspace(getCurrentWorkspaceId());
    setallRoles(roles);
    setLoaderForRole(false);
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      getAllRoles();
    }
  }, []);

  const handleInviteWorkspace = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    if (email === user?.email) {
      seterrorMessage("recheck email, you cannot invite by yourself");
      setLoader(false);
    } else {
      const data = {
        businessId: getCurrentWorkspaceId(),
        email: email,
        sentBy: user?._id,
        role: selectedRole,
        baseURL: INVITATION_URL,
      };
      const res = await ApiCall("post", businessEndpoints.inviteBusiness, data);
      if (res?.success) {
        toast.success("Invite sent Successfully");
        endApiCall();
      } else {
        seterrorMessage(res.error);
        setLoader(false);
      }
    }
  };

  return (
    <div
      id="invite_workspace"
      className={`modal fade add-new-contact mt-10 ${
        isOpenInviteWorkspaceModal ? "show" : ""
      }`}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: isOpenInviteWorkspaceModal ? "block" : "none" }}
    >
      <form onSubmit={handleInviteWorkspace}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="btn-close" onClick={endApiCall}>
                <span aria-hidden="true">x</span>
              </button>
              <h5 className="mb-4">Invite</h5>
              <div className="title title-sm mt-1 mb-4 fw-normal ">
                <span>
                  New members will gain access to public Spaces, Task and Many
                  other features.
                </span>
              </div>
              <div className="row gx-3">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Invite By email</label>
                    <input
                      disabled={senderEmail?.length > 0}
                      className="form-control"
                      type="email"
                      placeholder="Enter email"
                      required
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                  <div className="todo-toolbar">
                    {loaderForRole && <p>Loading roles...</p>}
                    {allRoles?.length > 0 && !loaderForRole && (
                      <>
                        <select
                          className="form-select form-select text-capitalize"
                          value={selectedRole}
                          onChange={(event) =>
                            setSelectedRole(event.target.value)
                          }
                        >
                          <option value="Select Role">Select Role</option>
                          {allRoles?.map((data) => {
                            return (
                              <option key={data?._id} value={data?._id}>
                                {data?.roleName}
                              </option>
                            );
                          })}
                        </select>
                      </>
                    )}
                    {allRoles?.length === 0 && !loaderForRole && (
                      <span className="text-danger pointer text-center">
                        There is no role exists in company,{" "}
                        <Link
                          onClick={endApiCall}
                          to={navigateToRoleList()}
                          className="text-primary text-underline"
                        >
                          {" "}
                          Create new
                        </Link>{" "}
                        role to continue{" "}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            <div className="modal-footer align-items-center">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={endApiCall}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={
                  loader || allRoles?.length === 0 || selectedRole === ""
                }
                className="btn btn-primary"
              >
                {loader ? <ApiLoader /> : "Send Invite"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InviteWorkspace;
