import React, { useState } from "react";
import { Avatar } from "../../../components/Avatar/Avatar";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import {
  dateFormatter,
  getName,
} from "../../../global-functions/globalFunctions";
import AssigneEmployeeDetails from "../../../components/AssigneEmployeeDetails/AssigneEmployeeDetails";
import UserInfoModal from "../../../components/UserInfoModal/UserInfoModal";
import ChangeDuedate from "../../../components/ChangeDuedate/ChangeDuedate";
import { useNavigate } from "react-router-dom";

const DetailsHeader = (props) => {
  const {
    taskDetails,
    taskStatusLoader,
    handleEditTask,
    handleReset,
    handleGetAllSpaceStatus,
    taskStatus,
    taskPriority,
    setisAssigneeEmployeeModal,
    setdueDate,
    dueDate,
    pleaseWait,
    user,
    handleGetTaskDetails,
    listName,
    spaceName,
    isShowSpace,
  } = props;
  const navigate = useNavigate();

  const [isEdit, setisEdit] = useState(false);
  const [userInfoData, setuserInfoData] = useState([]);
  // console.log("spaceName: ", spaceName);
  // console.log("listName: ", listName);

  return (
    <>
      <div className="row align-items-center border-bottom">
        <div className="col-12">
          <div className="row px-4 pt-3">
            <div className="col-sm-10">
              <span className="d-flex align-items-center gap-1 fs-7">
                <p>
                  {taskDetails?.taskType === "subtask"
                    ? "Sub Task ID :"
                    : "Task ID :"}
                </p>
                <p className="text-dark">{taskDetails?.taskReferenceId}</p>
              </span>
            </div>
            <div className="col-sm-2 text-end">
              <button
                type="button"
                className="info-close btn-close"
                onClick={() => {
                  handleReset();
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="d-flex align-items-center justify-content-between px-4 pb-2">
            <div className="d-block w-100">
              <div className="d-flex mt-2 justify-content-between">
                <div className="d-flex align-items-center gap-4">
                  <div className="d-flex align-items-center gap-2">
                    <p className="fs-7">Status: </p>
                    <div className="selectable-dropdown">
                      <div className="dropdown selectable-dropdown">
                        <span
                          style={{
                            border: `1px solid ${taskDetails?.status?.color}`,
                          }}
                          className={
                            pleaseWait
                              ? ``
                              : " fs-8 fw-medium text-uppercase text-dark rounded py-1 px-2 dropdown-toggle"
                          }
                          aria-expanded="false"
                          type="button"
                          data-bs-toggle="dropdown"
                          onClick={handleGetAllSpaceStatus}
                        >
                          <svg
                            className="circle"
                            width="12"
                            height="12"
                            viewBox="0 0 14 14"
                            fill={taskDetails?.status?.color}
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="15"
                              height="15"
                              fill={taskDetails?.status?.color}
                            />
                          </svg>
                          &nbsp;
                          {taskDetails?.status?.statusName}
                        </span>
                        <div
                          role="menu"
                          className="dropdown-menu"
                          style={{
                            maxHeight: "40vh",
                            overflow: "auto",
                          }}
                        >
                          {taskStatusLoader ? (
                            <span className="text-center">Loading...</span>
                          ) : (
                            <>
                              {taskStatus?.map((status) => (
                                <button
                                  disabled={
                                    taskDetails?.status?._id === status?._id
                                  }
                                  className="dropdown-item text-capitalize d-flex align-items-center gap-1"
                                  key={status._id}
                                  value={status._id}
                                  onClick={() => {
                                    handleEditTask(
                                      taskDetails?._id,
                                      "status",
                                      status?._id
                                    );
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    fill={status?.color}
                                    className="bi bi-square-fill"
                                    viewBox="0 0 16 16"
                                    style={{
                                      borderRadius: "3px",
                                    }}
                                  >
                                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z" />
                                  </svg>
                                  {status?.statusName}
                                </button>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center gap-2">
                    <p className="fs-7">Priority: </p>
                    <div className="selectable-dropdown pointer">
                      <div className="dropdown selectable-dropdown">
                        <span
                          style={{
                            border: `1px solid ${
                              taskDetails?.priority === "LOW"
                                ? "#0D6EFD"
                                : taskDetails?.priority === "MEDIUM"
                                ? "#FFC400"
                                : taskDetails?.priority === "NO_PRIORITY"
                                ? "#505050"
                                : "#FF0101"
                            }`,
                          }}
                          className={
                            pleaseWait
                              ? ""
                              : ` fs-8 fw-medium  text-dark text-capitalize rounded py-1 px-2 dropdown-toggle`
                          }
                          aria-expanded="false"
                          type="button"
                          data-bs-toggle="dropdown"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            className="bi bi-flag-fill me-2"
                            fill={
                              taskDetails?.priority === "LOW"
                                ? "#0D6EFD"
                                : taskDetails?.priority === "MEDIUM"
                                ? "#FFC400"
                                : taskDetails?.priority === "NO_PRIORITY"
                                ? "#505050"
                                : "#FF0101"
                            }
                            viewBox="0 0 16 16"
                          >
                            <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                          </svg>
                          {taskDetails?.priority === "NO_PRIORITY"
                            ? "NA"
                            : taskDetails?.priority}
                        </span>
                        <div role="menu" className="dropdown-menu">
                          {taskPriority?.map((priority, index) => (
                            <button
                              disabled={
                                taskDetails?.priority === priority?.value
                              }
                              key={index}
                              className="dropdown-item"
                              onClick={() => {
                                handleEditTask(
                                  taskDetails?._id,
                                  "priority",
                                  priority?.value
                                );
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                className="bi bi-flag-fill me-2"
                                fill={priority?.color}
                                viewBox="0 0 16 16"
                              >
                                <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.170.79-.260C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.230 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                              </svg>
                              {priority?.name}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="d-flex align-items-center">
                    <button
                      className="btn btn-xs btn-outline-secondary ms-1 fs-9"
                      onClick={() => {
                        navigate(navigateToMyGrievance(), {
                          state: {
                            moduleName: taskDetails?.name,
                            moduleId: taskDetails?._id,
                            moduleType: "Task",
                          },
                        });
                      }}
                    >
                      Grievance
                    </button>
                  </div> */}
                </div>

                <div className="avatar-group avatar-group-sm avatar-group-overlapped pointer me-2">
                  <p className="fs-7 mt-2">Assignee: &nbsp;</p>
                  {taskDetails?.assignedTo?.slice(0, 3)?.map((assine) => {
                    return (
                      <CustomTooltip text={assine.name} placement="bottom">
                        <span
                          data-bs-toggle="modal"
                          data-bs-target="#user_information_modal"
                          onClick={() => {
                            setuserInfoData(assine);
                          }}
                        >
                          <Avatar
                            name={assine?.name}
                            count={1}
                            image={assine?.image}
                            size="xxs"
                            color="primary"
                          />
                        </span>
                      </CustomTooltip>
                    );
                  })}
                  {taskDetails?.assignedTo?.length > 3 && (
                    <CustomTooltip text="view all assignee" placement="bottom">
                      <div className="selectable-dropdown">
                        <div className="dropdown selectable-dropdown">
                          <span
                            className="avatar avatar-xxs avatar-soft-indigo avatar-rounded"
                            data-bs-toggle="dropdown"
                          >
                            <span className="initial-wrap">
                              +{taskDetails?.assignedTo?.length - 3}
                            </span>
                          </span>
                          <AssigneEmployeeDetails
                            taskDetails={taskDetails?.assignedTo}
                          />
                        </div>
                      </div>
                    </CustomTooltip>
                  )}
                  <CustomTooltip text="add assignee" placement="bottom">
                    <div
                      className="avatar avatar-xxs avatar-soft-indigo avatar-rounded font-1"
                      onClick={() => {
                        setisAssigneeEmployeeModal(true);
                      }}
                    >
                      <span className="initial-wrap">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-plus-lg"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                          />
                        </svg>
                      </span>
                    </div>
                  </CustomTooltip>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="d-flex align-items-center gap-7 px-4 pb-2">
            <div className=" d-flex flex-column gap-1">
              <p className="fs-7">Created By</p>
              <span
                className={
                  pleaseWait
                    ? ""
                    : "text-dark fs-7 d-flex align-items-center gap-1"
                }
              >
                <CustomTooltip
                  text={taskDetails?.createdBy?.name}
                  placement="bottom"
                >
                  <Avatar
                    name={taskDetails?.createdBy?.name}
                    count={1}
                    image={taskDetails?.createdBy?.image}
                    size="xxs"
                    color="primary"
                  />
                </CustomTooltip>
                {getName(taskDetails?.createdBy, user?._id)}
              </span>
            </div>
            <div className=" d-flex flex-column gap-1">
              <p className="fs-7">Created Date</p>
              <span className={pleaseWait ? "" : "text-dark fs-7"}>
                {dateFormatter(taskDetails?.createdAt)}
              </span>
            </div>
            <div className=" d-flex flex-column gap-1">
              <span className="d-flex align-items-center gap-1">
                <p className="fs-7">Due Date</p>
                <span
                  className="p-1 circle shadow-hover pointer"
                  style={{ display: "grid", placeItems: "center" }}
                  onClick={() => {
                    setdueDate("");
                    setisEdit(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    fill="#008492"
                    className="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                </span>
              </span>
              <div className="form-group d-flex align-items-center mb-0 pb-0">
                {!isEdit && (
                  <span className="text-dark fs-7">
                    {taskDetails?.dueDate
                      ? dateFormatter(taskDetails?.dueDate)
                      : "No Due Date"}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <UserInfoModal user={userInfoData} />
      {isEdit && (
        <ChangeDuedate
          seteditDuedDate={setisEdit}
          editDueDate={isEdit}
          taskId={taskDetails}
          taskType={taskDetails?.taskType}
          handleGetTaskDetails={handleGetTaskDetails}
        />
      )}
    </>
  );
};

export default DetailsHeader;
