import React from "react";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { navigateToIndividualSpaces } from "../../../Navigation/Navigation";

const PollHeader = (props) => {
  const {
    handleGetPolls,
    PollCount,
    search,
    handleSearchSet,
    locationData,
    navigate,
    moduleType,
    handleChangeType,
    isModuleReadWriteAccessPoll,
  } = props;
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <a className="todoapp-title link-dark">
          <h1 className="font-4 me-2 ms-1">Polls</h1>
          {locationData && (
            <>
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-geo-alt"
                viewBox="0 0 16 16"
              >
                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </svg>
              <span
                onClick={() => {
                  if (locationData?.moduleType === "Space") {
                    navigate(
                      navigateToIndividualSpaces({
                        spacename: locationData?.moduleName,
                        id: locationData?.moduleId,
                      })
                    );
                  }
                }}
                className="text-primary ms-1 pointer switch-text"
              >
                {locationData?.moduleName}
              </span>
            </>
          )}
          {isModuleReadWriteAccessPoll && (
            <div className="ms-3">
              <button
                className="btn btn-sm btn-outline-secondary flex-shrink-0 d-lg-inline-block"
                data-bs-toggle="modal"
                data-bs-target="#add_new_poll"
              >
                Create Poll
              </button>
            </div>
          )}
        </a>
      </div>
      <div className="d-flex align-items-center">
        <span className="d-md-inline text-capitalize">
          Total Poll : {PollCount}
        </span>

        <span className="text-dark fs-5 fw-medium ps-2"></span>
      </div>

      <div className="todo-options-wrap">
        <div className="selectable-dropdown">
          <div className="dropdown selectable-dropdown">
            <span
              className={`fs-7 fw-medium  text-dark text-capitalize border rounded p-2`}
              aria-expanded="false"
              type="button"
              data-bs-toggle="dropdown"
            >
              <span className="text-secondary"> Type :&nbsp;</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-filter"
                viewBox="0 0 16 16"
              >
                <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
              </svg>
              &nbsp;{" "}
              {moduleType === ""
                ? "All"
                : moduleType === "Business"
                ? "Company"
                : moduleType}
            </span>
            <div
              role="menu"
              className="dropdown-menu"
              style={{ maxHeight: "40vh", overflow: "auto" }}
            >
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    moduleType === "" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    handleChangeType("");
                  }}
                >
                  1. All
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    moduleType === "Business" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    handleChangeType("Business");
                  }}
                >
                  2. Company
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    moduleType === "Space" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    handleChangeType("Space");
                  }}
                >
                  3. Space
                </div>
              </div>
            </div>
          </div>
        </div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="refresh poll list"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap">
            <span className="feather-icon" onClick={handleGetPolls}>
              <i className="ri-refresh-line" title="Refresh Poll"></i>
            </span>
          </span>
        </span>
        {/* {locationData && !isWorkspacewisePolls && (
          <span
            className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
            title="Go back to workspacewise meetings"
            data-bs-original-title="home"
            onClick={() => {
              setisWorkspacewisePolls(true);
            }}
          >
            <span className="btn-icon-wrap">
              <span style={{ marginTop: "-8px" }}>
                <CustomTooltip
                  text={"Go back to workspacewise polls"}
                  placement="right"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-house"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z" />
                  </svg>{" "}
                </CustomTooltip>{" "}
              </span>
            </span>
          </span>
        )} */}
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-sm-block d-none" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search poll"
            value={search}
            onChange={handleSearchSet}
          />
        </form>
      </div>
    </header>
  );
};

export default PollHeader;
