import React from "react";
import maintenance from "../../utils/svg/maintenance.svg";
import wave from "../../utils/svg/maintenance-back.svg";

const MaintainancePage = () => {
  const reloadMaintenancePage = () => {
    window.location.reload();
  };

  return (
    <div
      className="row w-100 mnh-100vh align-items-center mx-0"
      style={{
        backgroundImage: `url(${wave})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="col-sm-12 col-md-6 col-lg-6 text-dark">
        <div className="d-flex flex-column gap-3 px-5 text-responsive">
          <p className="fs-4 fw-semibold">WE ARE UNDER</p>
          <p className="fs-1 fw-bolder">MAINTAINANCE</p>
          <p className="fw-medium fs-5">
            We're performing scheduled maintainance we should be back online
            shortly
          </p>
          <p className="fs-4 fw-semibold text-primary text-responsive">
            - Opsshift Team
          </p>
          <span className="d-flex justify-content-end">
            <button
              className="maintenance-responsive-btn btn border pointer"
              onClick={reloadMaintenancePage}
            >
              Refresh
            </button>
          </span>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-6 text-dark">
        <div className="d-flex flex-column gap-5">
          <img className="maintenance-svg" src={maintenance} alt="" />
        </div>
      </div>
    </div>
  );
};

export default MaintainancePage;
