import React, { useEffect, useState } from "react";
import avatar from "../../../assets/images/avatar3.jpg";
import {
  getCurrentWorkspaceId,
  getTimeAgo,
  startApiCall,
  getName,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { logsEndPoints } from "../../../api/Endpoints";
import CustomTooltip from "../../CustomTooltip/CustomTooltip";
import { Avatar } from "../../Avatar/Avatar";
import { useNavigate } from "react-router-dom";
import { navigateToLog } from "../../../Navigation/Navigation";
import DataLoader from "../../Loaders/DataLoader/DataLoader";
import { useSelector } from "react-redux";

const NotificationSubMenu = ({ isShowLog }) => {
  const user = useSelector((state) => state.user);
  const [loader, setloader] = useState(false);
  const [logData, setlogData] = useState([]);
  const navigate = useNavigate();

  const handleGetLogs = async () => {
    startApiCall(null, setloader);
    const sendData = {
      page: 1,
      limit: 12,
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall("post", logsEndPoints.getLogs, sendData);
    if (res?.success) {
      setlogData(res?.logs?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (isShowLog && getCurrentWorkspaceId()) {
      handleGetLogs();
    }
  }, [isShowLog]);

  return (
    <>
      <div className="dropdown-menu dropdown-menu-end p-0 ">
        <h6 className="dropdown-header px-4 fs-6">
          Company Activity
          <span onClick={handleGetLogs} className="ms-1 fw-bold pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
            </svg>
          </span>
        </h6>

        <div
          data-simplebar
          className="dropdown-body  p-2 simple-scrollbar"
          style={{ overflowY: "scroll" }}
        >
          {!loader && logData?.length > 0 && (
            <>
              {logData?.map((log) => (
                <span className="dropdown-item" style={{ overflow: "hidden" }}>
                  <div className="media d-flex align-items-center gap-3">
                    <span className="align-items-center jsutify-content-center">
                      <CustomTooltip
                        text={log?.actionBy?.name}
                        placement="bottom"
                      >
                        <Avatar
                          name={log?.actionBy?.name}
                          count={1}
                          image={log?.actionBy?.image}
                          size="xs"
                        />
                      </CustomTooltip>
                    </span>
                    <div className="media-body">
                      <div className="d-flex flex-column">
                        <p>
                          <span className="text-medium-em font-2 text-capitalize">
                            {log?.actionTitle}
                          </span>
                          &nbsp;&nbsp;
                          <span className="text-body font-2  text-">
                            {getTimeAgo(log?.createdAt)}
                          </span>
                        </p>
                        <span className="mt-1 fw-medium">
                          <span className="text-high-em font-3">
                            <span className="text-primary">
                              {getName(log?.actionBy, user?._id)}
                            </span>
                            &nbsp;
                            {log?.actionDescription}
                          </span>
                          {log?.actionOn && (
                            <span className="text-primary font-3">
                              &nbsp;
                              <CustomTooltip
                                text={log?.actionOn?.name}
                                placement="bottom"
                              >
                                <Avatar
                                  name={log?.actionOn?.name}
                                  count={1}
                                  image={log?.actionOn?.image}
                                  size="xxs"
                                />
                              </CustomTooltip>
                              &nbsp;{log?.actionOn?.name}&nbsp;
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </span>
              ))}
            </>
          )}
          {loader && <DataLoader title="Loading Company logs..." />}
          {logData?.length === 0 && !loader && (
            <span className="text-center"> No Company Logs</span>
          )}
        </div>

        {!loader && (
          <div className="dropdown-footer ">
            <span
              className="pointer"
              onClick={() => {
                navigate(
                  navigateToLog({
                    module: "Workspace",
                    id: getCurrentWorkspaceId(),
                  })
                );
              }}
            >
              <u>View all activity</u>
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationSubMenu;
