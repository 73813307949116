import React from "react";
import human from "../../../assets/images/human-subject.png";
import SkeletonConstant from "../../../components/Constant/SkeletonConstant";
import NoData from "../../../components/NoData/NoData";
import ReactConfetti from "react-confetti";
import { useSelector } from "react-redux";

const skeleton = (
  <>
    <SkeletonConstant
      width="w-100"
      height="h-160p"
      additional="mb-1 rounded rounded-top-start-lg rounded-top-end-lg"
    />
    <SkeletonConstant width="w-100" height="h-200p" additional="rounded-4" />
  </>
);

const badge = (
  <svg
    width="53"
    height="53"
    viewBox="0 0 53 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.9285 28.5149L45.0256 35.3642C45.1942 35.6462 45.2894 35.9635 45.303 36.2888C45.3166 36.6141 45.2483 36.9378 45.1038 37.232C44.9593 37.5262 44.7431 37.7822 44.4736 37.9781C44.204 38.1739 43.8893 38.3038 43.5564 38.3565L43.3209 38.3786L43.0875 38.3766L36.9751 37.9945L34.2597 43.2954C34.1123 43.5824 33.896 43.8314 33.6288 44.0215C33.3616 44.2117 33.0513 44.3375 32.7236 44.3887C32.3958 44.4398 32.0602 44.4147 31.7445 44.3155C31.4289 44.2163 31.1423 44.0458 30.9087 43.8182L30.7378 43.6292L30.5877 43.408L26.4864 36.5567C29.3735 36.3826 32.1797 35.566 34.6852 34.1708C37.1907 32.7757 39.3275 30.8399 40.9285 28.5149Z"
      fill="#FFC207"
    />
    <path
      d="M24.1194 36.5568L20.0223 43.4101C19.8563 43.6877 19.6239 43.9231 19.3446 44.0966C19.0653 44.2701 18.7471 44.3767 18.4164 44.4076C18.0858 44.4385 17.7523 44.3927 17.4436 44.2742C17.135 44.1556 16.8602 43.9675 16.6421 43.7258L16.4837 43.5247L16.3503 43.2954L13.6328 37.9966L7.52465 38.3787C7.18819 38.3996 6.8515 38.3414 6.54341 38.2093C6.23532 38.0771 5.96501 37.8749 5.75565 37.6199C5.54628 37.3649 5.40408 37.0647 5.34124 36.7451C5.2784 36.4254 5.29679 36.0958 5.39482 35.7846L5.47818 35.5694L5.58238 35.3683L9.68366 28.513C11.2835 30.8379 13.4192 32.774 15.9235 34.1698C18.4279 35.5656 21.233 36.3813 24.1194 36.5568Z"
      fill="#FFC207"
    />
    <path
      d="M25.3061 4.41699L25.8062 4.42504C29.5864 4.55017 33.1684 6.0872 35.7967 8.71191C38.425 11.3366 39.894 14.8438 39.8939 18.4936L39.8877 18.8817L39.8731 19.2678L39.8356 19.7605L39.7814 20.2471L39.7314 20.6051C39.5943 21.4731 39.3731 22.3268 39.0708 23.155L38.829 23.7743L38.5102 24.4742C37.3304 26.8993 35.458 28.9493 33.1128 30.3834C30.7677 31.8175 28.0472 32.5761 25.2708 32.5702C22.4944 32.5643 19.7774 31.7941 17.4388 30.35C15.1003 28.906 13.2373 26.848 12.0686 24.4179L11.7977 23.8206L11.6893 23.5531L11.5226 23.1168L11.3246 22.5256C11.2539 22.2966 11.1893 22.0659 11.1308 21.8338L11.0058 21.2888L10.9036 20.7439L10.862 20.4643L10.7807 19.8148L10.7307 19.0808L10.7182 18.4936C10.7181 14.8438 12.1872 11.3366 14.8154 8.71191C17.4437 6.0872 21.0257 4.55017 24.8059 4.42504L25.3061 4.41699Z"
      fill="#FFC207"
    />
    <path d="M40.9285 28.5149L45.0256 35.3642C45.1942 35.6462 45.2894 35.9635 45.303 36.2888C45.3166 36.6141 45.2483 36.9378 45.1038 37.232C44.9593 37.5262 44.7431 37.7822 44.4736 37.9781C44.204 38.1739 43.8893 38.3038 43.5564 38.3565L43.3209 38.3786L43.0875 38.3766L36.9751 37.9945L34.2597 43.2954C34.1123 43.5824 33.896 43.8314 33.6288 44.0215C33.3616 44.2117 33.0513 44.3375 32.7236 44.3887C32.3958 44.4398 32.0602 44.4147 31.7445 44.3155C31.4289 44.2163 31.1423 44.0458 30.9087 43.8182L30.7378 43.6292L30.5877 43.408L26.4864 36.5567C29.3735 36.3826 32.1797 35.566 34.6852 34.1708C37.1907 32.7757 39.3275 30.8399 40.9285 28.5149Z" />
    <path d="M24.1194 36.5568L20.0223 43.4101C19.8563 43.6877 19.6239 43.9231 19.3446 44.0966C19.0653 44.2701 18.7471 44.3767 18.4164 44.4076C18.0858 44.4385 17.7523 44.3927 17.4436 44.2742C17.135 44.1556 16.8602 43.9675 16.6421 43.7258L16.4837 43.5247L16.3503 43.2954L13.6328 37.9966L7.52465 38.3787C7.18819 38.3996 6.8515 38.3414 6.54341 38.2093C6.23532 38.0771 5.96501 37.8749 5.75565 37.6199C5.54628 37.3649 5.40408 37.0647 5.34124 36.7451C5.2784 36.4254 5.29679 36.0958 5.39482 35.7846L5.47818 35.5694L5.58238 35.3683L9.68366 28.513C11.2835 30.8379 13.4192 32.774 15.9235 34.1698C18.4279 35.5656 21.233 36.3813 24.1194 36.5568Z" />
    <path d="M25.3061 4.41699L25.8062 4.42504C29.5864 4.55017 33.1684 6.0872 35.7967 8.71191C38.425 11.3366 39.894 14.8438 39.8939 18.4936L39.8877 18.8817L39.8731 19.2678L39.8356 19.7605L39.7814 20.2471L39.7314 20.6051C39.5943 21.4731 39.3731 22.3268 39.0708 23.155L38.829 23.7743L38.5102 24.4742C37.3304 26.8993 35.458 28.9493 33.1128 30.3834C30.7677 31.8175 28.0472 32.5761 25.2708 32.5702C22.4944 32.5643 19.7774 31.7941 17.4388 30.35C15.1003 28.906 13.2373 26.848 12.0686 24.4179L11.7977 23.8206L11.6893 23.5531L11.5226 23.1168L11.3246 22.5256C11.2539 22.2966 11.1893 22.0659 11.1308 21.8338L11.0058 21.2888L10.9036 20.7439L10.862 20.4643L10.7807 19.8148L10.7307 19.0808L10.7182 18.4936C10.7181 14.8438 12.1872 11.3366 14.8154 8.71191C17.4437 6.0872 21.0257 4.55017 24.8059 4.42504L25.3061 4.41699Z" />
  </svg>
);

const starIcon = (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.24423 6.33992L0.864231 7.26492L0.751231 7.28792C0.58017 7.33333 0.424226 7.42333 0.299323 7.54872C0.17442 7.67412 0.0850342 7.83041 0.0402929 8.00165C-0.00444843 8.17289 -0.00294197 8.35293 0.0446583 8.5234C0.0922587 8.69386 0.184247 8.84864 0.311231 8.97192L4.93323 13.4709L3.84323 19.8259L3.83023 19.9359C3.81976 20.1128 3.8565 20.2894 3.93668 20.4474C4.01686 20.6055 4.13761 20.7394 4.28657 20.8354C4.43552 20.9315 4.60732 20.9862 4.78438 20.994C4.96144 21.0018 5.13739 20.9625 5.29423 20.8799L11.0002 17.8799L16.6932 20.8799L16.7932 20.9259C16.9583 20.9909 17.1377 21.0109 17.313 20.9837C17.4883 20.9565 17.6532 20.8832 17.7908 20.7712C17.9284 20.6592 18.0338 20.5127 18.0961 20.3466C18.1583 20.1804 18.1753 20.0008 18.1452 19.8259L17.0542 13.4709L21.6782 8.97092L21.7562 8.88592C21.8677 8.74869 21.9407 8.58438 21.968 8.40972C21.9952 8.23506 21.9757 8.0563 21.9113 7.89165C21.847 7.72701 21.7402 7.58235 21.6017 7.47244C21.4633 7.36252 21.2982 7.29126 21.1232 7.26592L14.7432 6.33992L11.8912 0.559923C11.8087 0.392457 11.6809 0.251438 11.5224 0.152827C11.3639 0.0542165 11.1809 0.00195313 10.9942 0.00195312C10.8075 0.00195313 10.6246 0.0542165 10.466 0.152827C10.3075 0.251438 10.1798 0.392457 10.0972 0.559923L7.24423 6.33992Z"
      fill="#FFC207"
    />
  </svg>
);

const trophy = (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_8260_18380"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="48"
      height="48"
    >
      <path d="M0 0H48V48H0V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_8260_18380)">
      <path
        d="M7.5797 7.579H15.1587C16.5532 7.579 17.685 7.44763 17.685 8.84216V6.31584C17.685 5.64582 17.4188 5.00324 16.945 4.52947C16.4713 4.05569 15.8287 3.78953 15.1587 3.78953H5.05339C2.52707 3.78953 0.527494 6.47374 1.26391 10.1053C1.26391 10.1053 3.52623 21.9474 3.68539 22.6838C4.10602 24.6316 6.3696 26.5264 8.78981 26.5264H17.5801C18.9747 26.5264 17.685 24.1314 17.685 22.7369V20.2106C17.685 20.8806 17.4188 21.5232 16.945 21.997C16.4713 22.4707 15.8287 22.7369 15.1587 22.7369H10.106C8.71149 22.7369 7.79065 21.4207 7.5797 20.2106C7.36876 19.0005 5.58013 10.9478 5.58013 10.9478C5.15823 8.52637 6.18518 7.579 7.5797 7.579Z"
        fill="#FFAC33"
      />
      <path
        d="M40.187 7.579H32.6081C31.2135 7.579 29.0548 7.44763 29.0548 8.84216V6.31584C29.0548 4.92132 31.2135 3.78953 32.6081 3.78953H42.7133C45.2397 3.78953 47.2405 6.47374 46.5028 10.1053C46.5028 10.1053 44.4401 22.003 44.2658 22.7369C43.8186 24.6316 41.6864 26.5264 39.2131 26.5264H30.1879C28.7921 26.5264 29.0548 24.1314 29.0548 22.7369V20.2106C29.0548 21.6051 31.2135 22.7369 32.6081 22.7369H37.6607C39.0552 22.7369 39.9773 21.4207 40.187 20.2106C40.3967 19.0005 42.1879 10.9478 42.1879 10.9478C42.6072 8.52637 41.5815 7.579 40.187 7.579Z"
        fill="#FFAC33"
      />
      <path
        d="M27.4231 29.053C27.4231 20.2639 24.0012 29.053 24.0012 29.053C24.0012 29.053 20.2117 20.2639 20.2117 29.053C20.2117 37.842 16.0547 41.6846 16.0547 41.6846H31.5802C31.5802 41.6846 27.4231 37.842 27.4231 29.053Z"
        fill="#FFAC33"
      />
      <path
        d="M37.8949 9.57141C37.8949 18.1293 29.3434 32.5508 24.0002 32.5508C18.6558 32.5508 10.1055 18.1293 10.1055 9.57141C10.1055 3.00931 11.3686 1.58952 13.8949 1.58952C15.6305 1.58952 21.5055 1.55541 24.0002 1.55541L32.8423 1.55289C36.5269 1.55289 37.8949 2.41815 37.8949 9.57141Z"
        fill="#FFCC4D"
      />
      <path
        d="M35.3691 42.9483C35.3691 44.3428 35.5004 45.4746 34.1059 45.4746H13.8954C12.5008 45.4746 12.6322 44.3428 12.6322 42.9483V41.6851C12.6322 40.2906 13.9484 39.1588 15.2634 39.1588H32.8427C34.1589 39.1588 35.3691 40.2906 35.3691 41.6851V42.9483Z"
        fill="#C1694F"
      />
      <path
        d="M37.8949 45.0005C37.8949 45.9605 37.948 46.7373 36.1581 46.7373H11.8423C10.2103 46.7373 10.1055 45.9605 10.1055 45.0005V44.6847C10.1055 43.7247 10.8836 42.9478 11.8423 42.9478H36.1581C37.1181 42.9478 37.8949 43.7247 37.8949 44.6847V45.0005Z"
        fill="#C1694F"
      />
    </g>
  </svg>
);

const PerformanceBody = ({
  allAnalytics,
  loader,
  isModuleReadWriteAccessForPerformance,
  performanceKey
}) => {
  const user = useSelector((state) => state.user);
  const filteredAnalytics = isModuleReadWriteAccessForPerformance
  ? allAnalytics?.filter(
    (item) => item?.dataType === "analytics" || item?.dataType === "check"
    )
    : allAnalytics?.filter(
      (item) =>
      (item.dataType === "analytics" || item?.dataType === "check") &&
      item.employeeId === user?._id
      );
      
  const renderEmployeeCard = (item, index, noTask) => {
    const ordinal =
      index !== null
        ? index === 0
          ? "st"
          : index === 1
          ? "nd"
          : index === 2
          ? "rd"
          : "th"
        : "th";

    return (
      <div
        className="w-325p d-flex flex-column align-items-center mx-auto"
        key={item?.employeeId}
      >
        <div className="mb-1" style={{ position: "relative" }}>
          {index <= 2 ? (
            <svg
              width="323"
              height="162"
              viewBox="0 0 323 162"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M323 161.5C323 140.292 318.823 119.291 310.707 99.6966C302.59 80.1025 290.694 62.2989 275.698 47.3023C260.701 32.3056 242.897 20.4096 223.303 12.2935C203.709 4.17732 182.708 -9.27052e-07 161.5 0C140.292 9.27052e-07 119.291 4.17732 99.6966 12.2935C80.1025 20.4096 62.2989 32.3056 47.3022 47.3023C32.3056 62.2989 20.4096 80.1026 12.2935 99.6966C4.17732 119.291 -1.8541e-06 140.292 0 161.5L161.5 161.5H323Z"
                fill="url(#paint0_linear)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="76.5"
                  y1="33.5"
                  x2="161.5"
                  y2="323"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.149" stopColor="#B0E2F7" stopOpacity="0.46" />
                  <stop offset="0.479506" stopColor="#9CD0FF" />
                </linearGradient>
              </defs>
            </svg>
          ) : (
            <svg
              width="327"
              height="126"
              viewBox="0 0 327 126"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="327" height="126" rx="10" fill="#DEE6FF" />
            </svg>
          )}

          <div
            style={{
              position: "absolute",
              bottom: index <= 2 ? "-30px" : "-18px",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="d-flex align-items-end gap-2">
              <div className="text-nowrap">
                <div className="fw-semibold text-dark fs-5 w-160p text-truncate">
                  {item?.employeeName}
                </div>
                <div className="d-flex align-items-center gap-1">
                  {starIcon}
                  <p className="mb-0 w-135p text-truncate">
                    {item?.permissions?.map((permission) => (
                      <span key={permission._id}>{permission.roleName}</span>
                    ))}
                  </p>
                </div>
              </div>
              <img
                className="h-80p w-80p rounded-circle"
                style={{
                  objectFit: "cover",
                  objectPosition: "top",
                }}
                src={item?.employeeImage ? item?.employeeImage : human}
                alt=""
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="card mb-4 w-100">
          <div className="card-body d-flex flex-column gap-3">
            {isModuleReadWriteAccessForPerformance && index <= 2 && (
              <ReactConfetti
                width={300}
                height={200}
                numberOfPieces={20}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            )}

            <div style={{ position: "relative", width: "48px" }}>
              {isModuleReadWriteAccessForPerformance && (
                <p
                  className="mb-0 fw-semibold text-dark fs-8"
                  style={{
                    position: "absolute",
                    top: "35%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  {item?.dataType === "analytics" && `${index + 1}${ordinal}`}
                </p>
              )}
              {index <= 2 ? trophy : badge}
            </div>

            <div className="row">
              <div className="col-6">
                <p className="mb-0 text-secondary">Total Task</p>
              </div>
              <div className="col-6">
                <p className="mb-0 text-dark">
                  {!noTask ? item?.statuses?.all : 0}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <p className="mb-0 text-secondary">Completed</p>
              </div>
              <div className="col-6">
                <p className="mb-0 text-dark">
                  {!noTask
                    ? `${item?.statuses?.[performanceKey]} / ${item?.statuses?.all}`
                    : 0}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <p className="mb-0 text-secondary">On time</p>
              </div>
              <div className="col-6">
                <p className="mb-0 text-dark">
                  {!noTask
                    ? `${item?.withinDueDate} / ${item?.statuses?.all}`
                    : 0}
                </p>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-6">
                <p className="mb-0 text-secondary">Final Score</p>
              </div>
              <div className="col-6">
                <p className="mb-0 text-dark">
                  {!noTask ? `${item?.finalScore?.toFixed(2)} %` : 0}
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex flex-column gap-3 overflow-hidden">
      {!loader && (
        <div className="d-flex flex-column gap-3">
          <div className="row">
            {filteredAnalytics?.slice(0, 3)?.map((item, index) => (
              <div className="col-lg-4 col-md-6" key={item?.employeeId}>
               {item?.statuses ?<>  {renderEmployeeCard(item, index, false)} </>:<NoData title="No Task Added" description="No Task for current company" /> }
                
              </div>
            ))}
          </div>
          {isModuleReadWriteAccessForPerformance &&
            filteredAnalytics?.length > 3 && (
              <p className="fw-semibold text-dark">Other Performers</p>
            )}
          <div className="row">
            {filteredAnalytics?.slice(3)?.map((item, index) => (
              <div className="col-lg-4 col-md-6" key={item?.employeeId}>
                {renderEmployeeCard(
                  item,
                  index + 3,
                  item?.dataType === "check" ? true : false
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {!loader && allAnalytics?.length === 0 && (
        <NoData title="No Employee Data" />
      )}
      {loader && (
        <div className="d-flex flex-column gap-7">
          <div className="row g-3">
            {[...Array(3)].map((_, i) => (
              <div className="col-lg-4 col-md-6" key={i}>
                <div className="w-325p loading-skeleton mx-auto">
                  {skeleton}
                </div>
              </div>
            ))}
          </div>
          <div className="row g-3">
            {[...Array(3)].map((_, i) => (
              <div className="col-lg-4 col-md-6" key={i}>
                <div className="w-325p loading-skeleton mx-auto">
                  {skeleton}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PerformanceBody;
