import React, { useState } from "react";
import PauseAllNotificationsModal from "./PauseAllNotificationsModal";

const Notifications = () => {
  const [showModal, setShowModal] = useState(false);

  const toggleOptions = [
    {
      id: "spaces",
      name: "Spaces",
    },
    {
      id: "workspaces",
      name: "Company",
    },
    {
      id: "tasks",
      name: "Tasks",
    },
    {
      id: "roles",
      name: "Roles",
    },
    {
      id: "staff_management",
      name: "Staff management",
    },
    {
      id: "leave",
      name: "Leave",
    },
    {
      id: "inventory",
      name: "Inventory",
    },
    {
      id: "communications",
      name: "Communications",
    },
    {
      id: "grievance",
      name: "Grievance",
    },
    {
      id: "vendors",
      name: "Vendors",
    },
    {
      id: "crm",
      name: "CRM",
    },
  ];
  const [toggleStates, setToggleStates] = useState(
    toggleOptions.reduce((acc, option) => {
      acc[option.id] = true;
      return acc;
    }, {})
  );
  const handleToggleChange = (id) => {
    setToggleStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <div className="d-flex flex-column gap-2" style={{ width: "90%" }}>
        <span className="text-dark fs-4 fw-semibold mb-0">Notifications</span>
        <div className="card rounded-7 px-4 py-5">
          <div className="form-check form-switch d-flex align-items-center justify-content-between ps-0">
            <label className="form-check-label fs-5" htmlFor="pause_all">
              Pause all
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="pause_all"
              style={{ transform: "scale(1.5)" }}
              checked={showModal}
              onChange={handleToggleModal}
            />
          </div>
        </div>
        <div
          className="d-flex flex-column gap-2 overflow-auto simple-scrollbar"
          style={{ height: "60vh" }}
        >
          {toggleOptions?.map((item) => (
            <div
              className="card rounded-7 border-0 border-bottom"
              key={item?.id}
            >
              <div className="card-body">
                <p className="text-dark fw-semibold fs-5 mb-2">{item?.name}</p>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name={`toggle_${item.id}`}
                    id={`toggle_on_${item.id}`}
                    checked={toggleStates[item.id]}
                    onChange={() => handleToggleChange(item.id)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`toggle_on_${item.id}`}
                  >
                    On
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name={`toggle_${item.id}`}
                    id={`toggle_off_${item.id}`}
                    checked={!toggleStates[item.id]}
                    onChange={() => handleToggleChange(item.id)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`toggle_off_${item.id}`}
                  >
                    Off
                  </label>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {showModal && (
        <PauseAllNotificationsModal
          showModal={showModal}
          handleToggleModal={handleToggleModal}
        />
      )}
    </>
  );
};

export default Notifications;
