import React, { useEffect, useState } from "react";
import FormatDate from "../../components/FormatDate/FormatDate";
import { Avatar } from "../../components/Avatar/Avatar";
import {
  getAllEmployeesPrivateAccess,
  getCurrentWorkspaceId,
  getName,
  startApiCall,
} from "../../global-functions/globalFunctions";
import DataLoader from "../../components/Loaders/DataLoader/DataLoader";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";
import { useNavigate, useParams } from "react-router-dom";
import { logsEndPoints } from "../../api/Endpoints";
import ApiCall from "../../api/ApiCall";
import NoData from "../../components/NoData/NoData";
import LogsTab from "../../components/LogsTab/LogsTab";
import { navigateToLog } from "../../Navigation/Navigation";

function SpaceMoreInfo({
  moreInfoDetails,
  setspaceMoreInfoModal,
  user,
  spaceMoreInfoModal,
  setuserInfoData,
  isModuleReadWriteAccessForSpace,
}) {
  const { spacename } = useParams();
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [allEmployees, setallEmployees] = useState([]);
  const [logData, setlogData] = useState([]);

  // ***** DATE ******
  var date = new Date();
  var yesterday = new Date();
  yesterday.setDate(date.getDate() - 1);

  const handleGetAllEmployeeFromSpace = async () => {
    startApiCall(null, setloader);
    const res = await getAllEmployeesPrivateAccess({
      businessId: getCurrentWorkspaceId(),
      employeeId: user?._id,
      empFor: "space",
      pageNumber: 1,
      pageLimit: 100000,
      spaceId: moreInfoDetails?._id,
    });
    setallEmployees(res?.employees);
    setloader(false);
  };
  const handleGetLogs = async () => {
    startApiCall(null, setloader2);
    const sendData = {
      page: 1,
      limit: 20,
      businessId: getCurrentWorkspaceId(),
      moduleId: moreInfoDetails?._id,
      moduleType: "Space",
      // startDate: date,
      // endDate: Yesterday.toISOString(),
    };
    const res = await ApiCall("post", logsEndPoints.getLogs, sendData);
    if (res?.success) {
      setlogData(res?.logs?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    if (spaceMoreInfoModal && moreInfoDetails?.isPrivate) {
      handleGetAllEmployeeFromSpace();
    }
  }, [spaceMoreInfoModal]);

  return (
    <div className="details-modal">
      <div data-simplebar className="nicescroll-bar simple-scrollbar">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <div className="avatar avatar-icon avatar-soft-purple avatar-sm">
              <span className="initial-wrap">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill={moreInfoDetails?.themeColor}
                  class="bi bi-houses-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.207 1a1 1 0 0 0-1.414 0L.146 6.646a.5.5 0 0 0 .708.708L1 7.207V12.5A1.5 1.5 0 0 0 2.5 14h.55a2.51 2.51 0 0 1-.05-.5V9.415a1.5 1.5 0 0 1-.56-2.475l5.353-5.354L7.207 1Z" />
                  <path d="M8.793 2a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708L8.793 2Z" />
                </svg>
              </span>
            </div>
            &nbsp;
            <div
              className="file-name"
              style={{ fontSize: "28px", textTransform: "capitalize" }}
            >
              {moreInfoDetails?.name}
            </div>
          </div>

          <button
            type="button"
            className="info-close btn-close pb-4"
            onClick={() => {
              setspaceMoreInfoModal(false);
            }}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <ul className="nav nav-justified nav-light nav-tabs nav-segmented-tabs active-theme mt-5">
          <li className="nav-item">
            <a
              className="nav-link active"
              data-bs-toggle="tab"
              href="#tab_info"
            >
              <span className="nav-link-text">Details</span>
            </a>
          </li>
          <li className="nav-item" onClick={handleGetLogs}>
            <a className="nav-link" data-bs-toggle="tab" href="#tab_activity">
              <span className="nav-link-text">Activity</span>
            </a>
          </li>
        </ul>
        <div className="tab-content mt-1">
          <div className="tab-pane fade show active" id="tab_info">
            <div className="collapse-simple">
              <div className="card">
                <div className="card-header">
                  <a
                    role="button"
                    data-bs-toggle="collapse"
                    href="#space_basic_info"
                    aria-expanded="true"
                  >
                    Basic Information
                  </a>
                </div>
                <div id="space_basic_info" className="collapse show">
                  <div className="card-body">
                    <ul className="fm-info">
                      <li>
                        <span>Space Name:</span>
                        <span className="text-capitalize">
                          {moreInfoDetails?.name}
                        </span>
                      </li>
                      <li>
                        <span>Created by :</span>
                        <span>
                          <Avatar
                            name={moreInfoDetails?.createdBy?.name}
                            count={1}
                            image={moreInfoDetails?.createdBy?.image}
                            size="xss"
                            color="primary"
                          />
                          &nbsp;
                          {getName(moreInfoDetails?.createdBy, user?._id)}
                        </span>
                      </li>
                      <li>
                        <span>Date Modified :</span>
                        <span>{FormatDate(moreInfoDetails?.updatedAt)}</span>
                      </li>
                      <li>
                        <span>Date Created :</span>
                        <span>{FormatDate(moreInfoDetails?.createdAt)}</span>
                      </li>
                      <li>
                        <span>Total Role :</span>
                        <span>
                          {moreInfoDetails?.folders?.length === 0
                            ? "No Role"
                            : moreInfoDetails?.folders?.length}
                        </span>
                      </li>
                      <li>
                        <span>Total Sub Roles :</span>
                        <span>
                          {moreInfoDetails?.lists?.length === 0
                            ? "No Sub Role"
                            : moreInfoDetails?.lists?.length}
                        </span>
                      </li>
                      <li>
                        <span>Privacy :</span>
                        {moreInfoDetails?.isPrivate && (
                          <span className="badge badge-md badge-pill badge-soft-primary">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-lock"
                              viewBox="0 0 16 16"
                              stroke-width="25"
                            >
                              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
                            </svg>
                            &nbsp; Private
                          </span>
                        )}
                        {!moreInfoDetails?.isPrivate && (
                          <span className="badge badge-md badge-pill badge-soft-primary">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-globe"
                              viewBox="0 0 16 16"
                            >
                              <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                            </svg>
                            &nbsp; Public
                          </span>
                        )}
                        {isModuleReadWriteAccessForSpace && (
                          <span
                            data-bs-toggle="modal"
                            data-bs-target="#share_permission"
                            className="ms-4 text-primary pointer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-pencil-square"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path
                                fill-rule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                              />
                            </svg>
                          </span>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header">
                  <a
                    role="button"
                    data-bs-toggle="collapse"
                    href="#members"
                    aria-expanded="true"
                  >
                    <span>
                      Members
                      {moreInfoDetails?.isPrivate && (
                        <span className="badge badge-md badge-pill badge-soft-primary ms-2">
                          {moreInfoDetails?.privateSpaceAccess?.length}
                        </span>
                      )}
                    </span>
                  </a>
                </div>
                <div id="members" class="collapse show">
                  <div class="card-body pointer">
                    {loader && <DataLoader />}
                    {!loader && moreInfoDetails?.isPrivate && (
                      <ul class="hk-list">
                        {allEmployees?.map((emp) => {
                          return (
                            <CustomTooltip text={emp?.name} placement="bottom">
                              <li
                                data-bs-toggle="modal"
                                data-bs-target="#user_information_modal"
                                onClick={() => {
                                  setuserInfoData(emp);
                                }}
                              >
                                <Avatar
                                  name={emp?.name}
                                  count={1}
                                  image={emp?.image}
                                  size="xs"
                                />
                              </li>
                            </CustomTooltip>
                          );
                        })}

                        {isModuleReadWriteAccessForSpace && (
                          <li
                            data-bs-toggle="modal"
                            data-bs-target="#share_permission"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              fill="currentColor"
                              class="bi bi-plus-circle"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                          </li>
                        )}
                      </ul>
                    )}
                    {!loader && !moreInfoDetails?.isPrivate && (
                      <div class="card card-border note-block bg-success-light-5 mt-2">
                        <div class="card-body">
                          <p>
                            This Space is visible to all the members in
                            company.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="tab_activity">
            {!loader2 && logData?.length === 0 && (
              <div className="d-flex justify-content-center">
                <NoData title={"No Logs Found"} description />
              </div>
            )}
            {!loader2 && logData?.length > 0 && <LogsTab logData={logData} />}
            {!loader2 && logData?.length >= 20 && (
              <div
                className="fixed-bottom bg-white p-2 border-top d-flex justify-content-center"
                style={{
                  position: "absolute",
                }}
                onClick={() => {
                  navigate(
                    navigateToLog({ module: "Space", id: moreInfoDetails?._id })
                  );
                }}
              >
                <span className="text-primary pointer">
                  <u>View all activity</u>
                </span>
              </div>
            )}
            {loader2 && <DataLoader title="data is loading..." />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpaceMoreInfo;
