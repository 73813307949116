import React, { useState } from "react";
import { Avatar } from "../../../components/Avatar/Avatar";
import {
  dateFormatter,
  getTimeFormatter,
} from "../../../global-functions/globalFunctions";
import MeetingDetailsModal from "./MeetingDetailsModal";
import { navigateToIndividualSpaces } from "../../../Navigation/Navigation";

const MeetingCard = (props) => {
  const {
    meeting,
    index,
    pageLimit,
    page,
    user,
    handleChangeStatus,
    setisConfirmDelete,
    handleIsConfirmEdit,
    setuserInfoData,
    navigate,
    isModuleReadWriteAccessMeeting,
  } = props;
  const [isDetailsModalOpen, setisDetailsModalOpen] = useState(false);

  const handleStatusWiseColor = (status) => {
    let card = {};
    if (status === "upcoming") {
      card = {
        color: "#008492",
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#008492"
            className="bi bi-alarm mb-1"
            viewBox="0 0 16 16"
          >
            <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5z" />
            <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1h-3zm1.038 3.018a6.093 6.093 0 0 1 .924 0 6 6 0 1 1-.924 0zM0 3.5c0 .753.333 1.429.86 1.887A8.035 8.035 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1z" />
          </svg>
        ),
      };
    } else if (status === "completed") {
      card = {
        color: "rgb(36 202 38)",
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-check-circle mb-1"
            viewBox="0 0 16 16"
            color="rgb(36 202 38)"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        ),
      };
    } else if (status === "cancelled") {
      card = {
        color: "rgb(239 13 39)",
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-x-circle mb-1"
            viewBox="0 0 16 16"
            color="rgb(239 13 39)"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        ),
      };
    } else if (status === "inprogress") {
      card = {
        color: "#FFC700",
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            className="mb-1"
          >
            <path
              d="M2.75877 16.553C2.45402 16.553 2.20697 16.3059 2.20697 16.0012C2.20697 15.6964 2.45402 15.4494 2.75877 15.4494H3.86237V14.3458C3.86237 12.3702 5.01594 10.6652 6.68378 9.86545C7.00371 9.71204 7.17317 9.44925 7.17317 9.21491V8.44058C7.17317 8.20625 7.00371 7.94345 6.68378 7.79004C5.01594 6.99034 3.86237 5.28529 3.86237 3.30974V2.20614H2.75877C2.45402 2.20614 2.20697 1.95909 2.20697 1.65434C2.20697 1.34959 2.45402 1.10254 2.75877 1.10254L14.8984 1.10256C15.2031 1.10257 15.4502 1.34962 15.4502 1.65437C15.4502 1.95912 15.2031 2.20617 14.8984 2.20616L13.7948 2.20615V3.30974C13.7948 5.28529 12.6412 6.99034 10.9734 7.79004C10.6534 7.94345 10.484 8.20625 10.484 8.44058V9.21491C10.484 9.44925 10.6534 9.71204 10.9734 9.86545C12.6412 10.6652 13.7948 12.3702 13.7948 14.3458V15.4494H14.8984C15.2031 15.4494 15.4502 15.6964 15.4502 16.0012C15.4502 16.3059 15.2031 16.553 14.8984 16.553H2.75877ZM4.96597 2.20616V3.30976C4.96597 3.90204 5.09927 4.46334 5.33764 4.96518H12.3195C12.5579 4.46334 12.6912 3.90204 12.6912 3.30976V2.20616H4.96597ZM8.27677 9.21493C8.27677 9.9877 7.7491 10.5786 7.16092 10.8606C5.86141 11.4837 4.96597 12.8107 4.96597 14.3458C4.96597 14.3458 5.9214 12.9126 8.27677 12.7133V9.21493ZM9.38036 9.21493V12.7133C11.7357 12.9126 12.6912 14.3458 12.6912 14.3458C12.6912 12.8107 11.7957 11.4837 10.4962 10.8606C9.90803 10.5786 9.38036 9.9877 9.38036 9.21493Z"
              fill="#FFC700"
            />
          </svg>
        ),
      };
    }
    return card;
  };
  const handleDetailsDrawer = () => {
    setisDetailsModalOpen(!isDetailsModalOpen);
  };
  return (
    <>
      <div className="contact-list-view">
        <div className="d-flex justify-content-center gap-2 w-100 mb-1">
          <div>
            <p className="fw-400 fs-8">
              {getTimeFormatter(meeting?.scheduleTime)}
            </p>
            <div className="d-flex flex-column align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="17"
                viewBox="0 0 2 17"
                fill="none"
                className="mb-1"
              >
                <path
                  d="M1 0V16.5245"
                  stroke="#979797"
                  stroke-width="0.869712"
                />
              </svg>
              {handleStatusWiseColor(meeting?.status).svg}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="17"
                viewBox="0 0 2 17"
                fill="none"
              >
                <path
                  d="M1 0V16.5245"
                  stroke="#979797"
                  stroke-width="0.869712"
                />
              </svg>
            </div>

            <p className="fw-400 fs-8">
              {getTimeFormatter(meeting?.endMeetingTime)}
            </p>
          </div>
          <div
            onClick={handleDetailsDrawer}
            className="card card-border p-3 w-90 pointer"
            style={{
              borderRadius: "9.81px",
              border: `0.981px solid ${
                handleStatusWiseColor(meeting?.status).color
              }`,
            }}
          >
            <div className="d-flex justify-content-between">
              <div className="d-block">
                <span className="text-dark">
                  {(page - 1) * pageLimit + (index + 1)}.
                </span>
                &nbsp;
                <Avatar
                  name={meeting?.createdBy?.name}
                  count={1}
                  image={meeting?.createdBy?.image}
                  size="xss"
                  color="primary"
                />
                <span className="fw-400 ms-2 fs-5">
                  Hosted by {meeting?.createdBy?.name}
                </span>
                <br />
                <div className="d-flex mt-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    className="mt-1 ms-1"
                  >
                    <path
                      d="M5.41924 2.78027V0.818359H0.514465V5.72313H2.47637V11.6089H0.514465V16.5136H5.41924V14.5517H11.305V16.5136H16.2097V11.6089H14.2478V5.72313H16.2097V0.818359H11.305V2.78027H5.41924ZM11.305 3.76122V5.72313H13.2669V11.6089H11.305V13.5708H5.41924V11.6089H3.45733V5.72313H5.41924V3.76122H11.305ZM12.2859 1.79931H15.2288V4.74218H12.2859V1.79931ZM15.2288 12.5898V15.5327H12.2859V12.5898H15.2288ZM4.43828 15.5327H1.49542V12.5898H4.43828V15.5327ZM1.49542 4.74218V1.79931H4.43828V4.74218H1.49542Z"
                      fill="#6F6F6F"
                    />
                  </svg>
                  <span className="text-dark ms-2 fs-5">
                    {meeting?.meetingTitle}
                  </span>
                </div>
              </div>
              <div className="me-1">
                <div className="d-flex gap-2 justify-content-end">
                  <span className="fw-400 text-dark fs-5 text-capitalize">
                    {dateFormatter(meeting?.endMeetingTime)}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2"
                    height="30"
                    viewBox="0 0 2 30"
                    fill="none"
                  >
                    <path
                      d="M0.822266 0.791016V29.7292"
                      stroke="#979797"
                      stroke-width="0.980955"
                    />
                  </svg>
                  <span className="fw-400 text-dark fs-5 text-capitalize">
                    {meeting?.addressType}
                  </span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2"
                    height="30"
                    viewBox="0 0 2 30"
                    fill="none"
                  >
                    <path
                      d="M0.822266 0.791016V29.7292"
                      stroke="#979797"
                      stroke-width="0.980955"
                    />
                  </svg>
                  <span
                    className="fs-5  text-capitalize"
                    style={{
                      color: handleStatusWiseColor(meeting?.status).color,
                    }}
                  >
                    {meeting?.status}
                  </span>
                </div>
                <div className="mt-2">
                  {(meeting?.addressType === "onsite" ||
                    meeting?.addressType === "both") && (
                    <span className="mt-2  pointer me-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-house me-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z" />
                      </svg>
                      {meeting?.onsiteAddress}
                    </span>
                  )}
                  {meeting?.addressType === "both" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2"
                      height="30"
                      viewBox="0 0 2 30"
                      fill="none"
                    >
                      <path
                        d="M0.822266 0.791016V29.7292"
                        stroke="#979797"
                        stroke-width="0.980955"
                      />
                    </svg>
                  )}
                  {(meeting?.addressType === "offsite" ||
                    meeting?.addressType === "both") && (
                    <a
                      href={meeting?.offsiteAddress}
                      className="mt-2 text-decoration-underline pointer"
                      style={{ color: "#008492" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-link-45deg me-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
                        <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
                      </svg>
                      {meeting?.offsiteAddress}
                    </a>
                  )}
                </div>
                <p className="text-end font-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-geo-alt"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </svg>
                  <span className="text-dark ms-1">
                    {meeting?.moduleType === "Business"
                      ? "Company"
                      : meeting?.moduleType}
                    {meeting?.moduleId && `( ${meeting?.moduleId?.name})`}
                  </span>
                </p>
              </div>
            </div>
          </div>
          {isModuleReadWriteAccessMeeting && (
            <div className="todo-options-wrap">
              <div className="selectable-dropdown">
                <div className="dropdown selectable-dropdown">
                  <span
                    aria-expanded="false"
                    type="button"
                    data-bs-toggle="dropdown"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 27 27"
                      fill="none"
                    >
                      <path
                        d="M15.7328 21.5288C15.7328 22.9007 14.6206 24.0128 13.2487 24.0128C11.8768 24.0128 10.7646 22.9007 10.7646 21.5288C10.7646 20.1569 11.8768 19.0447 13.2487 19.0447C14.6206 19.0447 15.7328 20.1569 15.7328 21.5288ZM15.7328 13.2486C15.7328 14.6205 14.6206 15.7326 13.2487 15.7326C11.8768 15.7326 10.7646 14.6205 10.7646 13.2486C10.7646 11.8767 11.8768 10.7645 13.2487 10.7645C14.6206 10.7645 15.7328 11.8767 15.7328 13.2486ZM15.7328 4.96843C15.7328 6.34033 14.6206 7.45248 13.2487 7.45248C11.8768 7.45248 10.7646 6.34033 10.7646 4.96843C10.7646 3.59652 11.8768 2.48438 13.2487 2.48438C14.6206 2.48438 15.7328 3.59652 15.7328 4.96843Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <div
                    role="menu"
                    className="dropdown-menu"
                    style={{ maxHeight: "40vh", overflow: "auto" }}
                  >
                    {meeting?.status !== "cancelled" &&
                      meeting?.status !== "completed" && (
                        <>
                          <div className="d-flex justify-content-between">
                            <button
                              className="dropdown-item text-capitalize"
                              data-bs-toggle="modal"
                              data-bs-target="#add_new_meeting"
                              onClick={() => {
                                handleIsConfirmEdit(meeting);
                              }}
                            >
                              1. Edit Meeting
                            </button>
                          </div>
                          <div className="dropdown-divider"></div>
                          <div className="d-flex justify-content-between">
                            <button
                              className="dropdown-item text-capitalize"
                              onClick={() => {
                                handleChangeStatus(meeting?._id, "cancelled");
                              }}
                            >
                              2. Cancel Meeting
                            </button>
                          </div>
                          <div className="dropdown-divider"></div>
                        </>
                      )}
                    <div className="d-flex justify-content-between">
                      <button
                        className="dropdown-item text-capitalize"
                        onClick={() => {
                          handleIsConfirmEdit(meeting);
                          setisConfirmDelete(true);
                        }}
                      >
                        {meeting?.status !== "cancelled" &&
                        meeting?.status !== "completed"
                          ? 3
                          : 1}
                        . Delete Meeting
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isDetailsModalOpen && (
        <MeetingDetailsModal
          handleDetailsDrawer={handleDetailsDrawer}
          meeting={meeting}
          user={user}
          handleStatusWiseColor={handleStatusWiseColor}
          setuserInfoData={setuserInfoData}
          navigate={navigate}
        />
      )}
    </>
  );
};

export default MeetingCard;
