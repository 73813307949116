import React, { useState } from "react";
import { Avatar } from "../../../components/Avatar/Avatar";
import {
  dateAndTimeFormatter,
  getName,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { useNavigate } from "react-router-dom";

import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import ApiCall from "../../../api/ApiCall";
import { workspaceRoleEndPoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";

const RoleListTable = ({
  roleListData,
  user,
  handleGetAllRole,
  isModuleReadWriteAccessForRolelist,
  setmoduleData,
  setroleModal,
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [roleId, setRoleId] = useState("");

  const handleClose = () => {
    setIsOpen(false);
    seterrorMessage("");
    setLoader(false);
  };

  const handleDeteleWorkspaceRole = async () => {
    startApiCall(seterrorMessage, setLoader);
    const data = {
      roleId: roleId,
    };
    const res = await ApiCall("post", workspaceRoleEndPoints.deleteRole, data);
    if (res?.success) {
      toast.success("Role Delete Successfully");
      handleGetAllRole();
      handleClose();
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  return (
    <>
      <table id="datable_1" className="table nowrap w-100 mb-5">
        <thead style={{ textAlign: "center" }}>
          <tr>
            <th>No.</th>
            <th>Name</th>
            <th>Created Date</th>
            <th>Created By </th>
            <th>Module Access</th>
            {isModuleReadWriteAccessForRolelist && <th>Action</th>}
          </tr>
        </thead>
        <tbody style={{ textAlign: "center" }}>
          {roleListData?.map((data, index) => {
            return (
              <tr>
                <td>{index + 1}.</td>
                <td className="text-capitalize">{data?.roleName}</td>
                <td>{dateAndTimeFormatter(data?.createdAt)}</td>
                <td>
                  <div className="fs-7">
                    <span className="d-inline-block">
                      <Avatar
                        name={data?.createdBy?.name}
                        count={1}
                        image={data?.createdBy?.image}
                        size="xss"
                        color="primary"
                      />
                      &nbsp;{" "}
                      {data?.createdBy?.name
                        ? getName(data?.createdBy, user?._id)
                        : "Opsshift Team"}
                    </span>
                  </div>
                </td>
                <td>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {data?.roleName !== "owner"
                      ? `${
                          data?.modules?.length ? data?.modules?.length : "No"
                        } Modules`
                      : `All Modules`}
                    &nbsp;
                    {data?.modules?.length && data?.roleName !== "owner" && (
                      <>
                        <i
                          data-bs-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="ri-information-line info-icon"
                        ></i>
                        <div className="dropdown-menu">
                          <div className="dropdown-item mw-300p mh-350p overflow-auto">
                            <div className="row">
                              <div className="d-flex justify-content-between">
                                <span className="ms-5">Module</span>
                                <span>Access type</span>
                              </div>
                            </div>
                            <div className="dropdown-divider"></div>
                            <div className="row">
                              {data?.modules?.map((module, index) => {
                                return (
                                  <div
                                    className="d-flex justify-content-between"
                                    key={index}
                                  >
                                    <span className="text-capitalize">
                                      {index + 1} .&nbsp;&nbsp;
                                    </span>
                                    <span
                                      style={{ width: "200px" }}
                                      className="text-truncate"
                                    >
                                      {module.moduleName}
                                    </span>
                                    <span className="text-capitalize">
                                      {module?.permissionType}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </td>
                {isModuleReadWriteAccessForRolelist && (
                  <td>
                    {data?.roleName !== "owner" ? (
                      <span className="feather-icon pointer">
                        <i
                          data-bs-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="ri-more-fill"
                        ></i>
                        <div className="dropdown-menu">
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              setmoduleData(data);
                              setroleModal(true);
                            }}
                          >
                            <span
                              className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="btn-icon-wrap">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="23"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                  />
                                </svg>
                              </span>
                            </span>
                            Edit Role
                          </div>
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              setIsOpen(true);
                              setRoleId(data?._id);
                            }}
                          >
                            <span
                              className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-danger"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="btn-icon-wrap">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-trash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                </svg>
                              </span>
                            </span>
                            Delete Role
                          </div>
                        </div>
                      </span>
                    ) : (
                      "-"
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      <ConfirmModal
        isOpen={isOpen}
        onClose={handleClose}
        text="Are You Sure You Want To Delete This Role"
        loader={loader}
        errorMessage={errorMessage}
        handleConfirm={handleDeteleWorkspaceRole}
      />
    </>
  );
};

export default RoleListTable;
