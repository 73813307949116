import React from "react";

const DashboardHeader = ({ user, setActiveTab, activeTab }) => {
  return (
    <>
      <div className="hk-pg-header pg-header-wth-tab">
        <div className="d-flex">
          <div className="d-flex flex-wrap justify-content-between flex-1">
            <div className="mb-lg-0 mb-2 me-8">
              <h1 className="pg-title">
                Welcome, <span className="text-primary">{user?.name}</span>
              </h1>
              <p>Create Company, Manage & Assign Tasks Across Your Teams</p>
            </div>
          </div>
        </div>
        <ul className="nav nav-line nav-light nav-tabs">
          <li className="nav-item" onClick={() => setActiveTab("tab_block_1")}>
            <a
              className={`nav-link ${
                activeTab === "tab_block_1" ? "active" : ""
              }`}
              data-bs-toggle="tab"
              href="#tab_block_1"
            >
              <span className="nav-link-text">Overview</span>
            </a>
          </li>
          <li className="nav-item" onClick={() => setActiveTab("tab_block_2")}>
            <a
              className={`nav-link ${
                activeTab === "tab_block_2" ? "active" : ""
              }`}
              data-bs-toggle="tab"
              href="#tab_block_2"
            >
              <span className="nav-link-text">Task Analytics</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default DashboardHeader;
